export default function LeadItem({ item }) {
  return (
    <div className="d-flex flex-wrap border rounded my-2 p-2">
      <div className="name p-2">
        <div className="border-bottom p-1">نام و نام خانوادگی</div>
        <div className="p-1">{item?.name}</div>
      </div>
      <div className="mobile p-2">
        <div className="border-bottom p-1">موبایل</div>
        <div className="p-1">
          <a href={`tel:${item?.mobile}`}>{item?.mobile}</a>
        </div>
      </div>
      <div className="flex-grow-1 p-2 product">
        <div className="border-bottom p-1">محصول</div>
        <div className="p-1">{item?.description}</div>
      </div>
    </div>
  );
}
