import { useEffect, useState } from "react";
import ModalImg from "assets/images/IMG_20220311_170947_345.png";
import Swal from "sweetalert2";
import sendRequest from "hooks/useAxios";
import logo from "assets/images/oil/logo2.png";
import logoName from "assets/images/Logos/New Project.png";
export default function LogInNumber({ ChangeModal, login, SetNumber, number }) {
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  useEffect(() => {
    (() => {
      "use strict";

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.querySelectorAll(".needs-validation");
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms).forEach((form) => {
        form.addEventListener(
          "submit",
          (event) => {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    })();
  }, []);

  let SendNumber = (e) => {
    e.preventDefault();
    const pattern = /^09\d{9}$/;

    if (!pattern.test(number)) {
      setIsPhoneNumberValid(false);
      return "";
    }
    setIsPhoneNumberValid(true);

    // formData.append("step", "1");

    sendRequest("getlogincode", { mobile: number })
      .then(({ data }) => {
        if (data.status == 1) {
          ChangeModal(1);
          login(data.data.hashcode);
        } else if (data.status == 0) {
          Swal.fire({
            title: "خطا!",
            text: "لطفا دوباره امتحان کنید",
            type: "error",
            confirmButtonText: "باشه",
          });
        } else if (data.status == -1) {
          Swal.fire({
            title: "خطا!",
            text: "شما مجاز نیستید",
            type: "error",
            confirmButtonText: "باشه",
          });
        }
      })
      .catch((error) => {
        ChangeModal(1);
        login("gg");
        Swal.fire({
          title: "خطا",
          text: "لطفا دوباره امتحان کنید",
          type: "error",
          confirmButtonText: "باشه",
        });
      });
  };

  return (
    <form className="needs-validation" noValidate onSubmit={SendNumber}>
      <div className="modal-body text-center">
        {/* <img
          // src={logo}
          src={logoName}
          alt=""
          width="200px"
          className="my-4"
          style={{
            backgroundColor: "rgb(220 53 69)",
            padding: "5px",
            borderRadius: "7px",
          }}
        /> */}
        GHODSFLY
        <h5>ورود یا ثبت نام در ghodsfly </h5>
        <label htmlFor="number" className="mb-4 my-3 text-muted">
          برای ورود به ghodsfly شماره همراه خود را وارد کنید
        </label>
        <input
          onChange={SetNumber}
          value={number}
          type="tel"
          style={{
            height: "50px",
            borderRadius: "15px",
            direction: "ltr",
            fontFamily: "digits",
          }}
          id="number"
          placeholder="09*********"
          className="form-control w-75 mx-auto"
          required
        />
        {!isPhoneNumberValid && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "right",
              margin: "5px 60px 0px 0px",
            }}
          >
            شماره وارد شده اشتباه است.
          </div>
        )}
        {/* <input onChange={SetNumber} value={number} placeholder="" id="pass" class="form-control form-phone" type="tel" pattern="[0-9]{10}" required name="mobile" /> */}
        <div
          className="invalid-feedback text-start"
          style={{ marginRight: "63px" }}
        >
          لطفا شماره موبایل خودرا وارد کنید
        </div>
        <label
          htmlFor="number"
          className="text-start d-block my-3"
          style={{ marginRight: "63px", color: "#1D3354" }}
        >
          شماره موبایل خود را وارد کنید
        </label>
        <button
          onClick={SendNumber}
          style={{
            height: "50px",
            borderRadius: "15px",
            backgroundColor: "#dc3545",
            color: "white",
          }}
          className="btn  my-3 w-75"
        >
          ادامه
        </button>
      </div>
    </form>
  );
}
