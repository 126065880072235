import { LoopCircleLoading } from "react-loadingg";
export default function Loader() {
  return (
    <>
      <div
        className="loader-container"
        style={{
          position: "fixed",
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
          overflow: "hidden",
          backgroundColor: "white",
          zIndex: 500,
          opacity: 0.8,
        }}
      >
        <LoopCircleLoading />
      </div>
    </>
  );
}
