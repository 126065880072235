import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import sendRequest from "hooks/useAxios";
import { addCommas, removeCommas } from "@persian-tools/persian-tools";
import AddLead from "components/AddLead/AddLead";
import TinySlider from "./TinySlider";
export default function MoreInfoProduct({
  product,
  auth,
  showMoreInfoModal,
  setShowMoreInfoModal,
  setShowModal,
}) {
  const [sliderArray, setSliderArray] = useState([]);
  // const [brands, setBrands] = useState();
  // useEffect(() => {
  //   sendRequest("Find_brand").then(data => {
  //     setBrands(data);
  //     console.log("brand xxx", brands);
  //   });
  // }, []);
  // const showBrandName = () => {
  //   let x = [];
  //   console.log("product.brandid", product.brandid);
  //   x = brands.filter(v => v.id == product.brandid);
  //   return x.name;
  // };
  // console.log(product, "product xxx");
  useEffect(() => {
    if (product.otherMedia != undefined)
      setSliderArray([product?.url, ...product?.otherMedia?.map((v) => v.url)]);
    else setSliderArray([product?.url]);
  }, [product]);
  console.log("sliderArray", sliderArray);
  return (
    <Modal
      show={showMoreInfoModal}
      onHide={() => {
        setShowMoreInfoModal(false);
      }}
    >
      <Modal.Header closeButton>
        <h5 className="modal-title" id="exampleModalLabel">
          اطلاعات بیشتر
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            <div className="text-center w-100">
              <TinySlider array={sliderArray} />
              {/* <img
                  src={`https://ghodsfly.ir/data/${product.url}`}
                  style={{
                    width: "90%",
                    objectFit: "contain",
                    borderRadius: 20,
                  }}
                /> */}
            </div>
            <div
              className="border border-danger border-2 p-4 mt-3"
              style={{
                fontSize: 14,
                borderRadius: 10,
                whiteSpace: "wrap",
              }}
            >
              {" "}
              باتوجه به نوسانات نرخ ارز برای اطلاع از قیمت کالا لطفا با
              <a
                href="tel:09111714096"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                &nbsp; 09111714096
              </a>{" "}
              تماس بگیرید.
            </div>
            {/* {auth.userInfo.userType != "2" ? (
                <div
                  className="border border-danger border-2 p-4 mt-3"
                  style={{ fontSize: 12, borderRadius: 10 }}
                >
                  {product.main_discount == "0" ? (
                    <div>
                      <span>قیمت اصلی :</span>
                      <span className="fw-bold" style={{ fontSize: 14 }}>
                        {addCommas(product.main_price)}تومان
                      </span>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <span>قیمت اصلی :</span>
                        <span className="text-danger text-decoration-line-through">
                          {addCommas(product.main_price)}تومان
                        </span>
                      </div>

                      <div className="mt-3">
                        <span>قیمت باتخفیف :</span>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          {addCommas(
                            product.main_price - product.main_discount
                          )}
                          تومان
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="border border-danger border-2 p-4 mt-3"
                  style={{ fontSize: 12, borderRadius: 10 }}
                >
                  {product.partners_discount == "0" ? (
                    <div>
                      <span>قیمت همکار :</span>
                      <span className="fw-bold" style={{ fontSize: 14 }}>
                        {addCommas(product.partners_price)}تومان
                      </span>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <span>قیمت همکار :</span>
                        <span className="text-danger text-decoration-line-through">
                          {addCommas(product.partners_price)}تومان
                        </span>
                      </div>

                      <div className="mt-3">
                        <span>قیمت باتخفیف :</span>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          {addCommas(
                            product.partners_price - product.partners_discount
                          )}
                          تومان
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )} */}
          </div>
          {/* <h5 className="mt-3">{product.title}</h5> */}
          {/* {product.brandid != "0" && (
              <div className="mt-3" style={{ fontSize: 10 }}>
                برند:
                {showBrandName}
              </div>
            )} */}
          {(product.description != "" || product.bodytxt != "") && (
            <div className="mt-4 " style={{ fontSize: 14 }}>
              <span>توضیحات :</span>
              <div>{product.description}</div>
              <div dangerouslySetInnerHTML={{ __html: product.bodytxt }}></div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <div className="text-center">
          <button
            className=" btn buy-once fw-bold fs-5"
            style={{ backgroundColor: "#cddc39", color: "white" }}
            onClick={() => {
              setShowModal(true);
              setShowMoreInfoModal(false);
            }}
          >
            خرید با یک کلیک!
          </button>
        </div>
        <button
          type="button"
          className="btn btn-secondary"
          // data-bs-dismiss="modal"
          onClick={() => {
            setShowMoreInfoModal(false);
          }}
        >
          بستن
        </button>
        {/* <button type="button" className="btn btn-danger">
              افزودن به سبد خرید
            </button> */}
      </Modal.Footer>{" "}
    </Modal>
  );
}
