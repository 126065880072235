import "../main/header.scss";
import { FaShoppingCart } from "react-icons/fa";
import logo2 from "../../assets/images/oil/logo2.png";
import { NavLink, Link } from "react-router-dom";
import useAuthentication from "hooks/useAuthentication";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import { useContext, useState, useEffect } from "react";
import CartModal from "common/order/CartModal";
import Search from "./Search";
import Container from "components/LogIn/Container";
import CartItemsContext from "context/CardItemsProvider";
import oilLogo from "assets/images/Logos/New Project.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";

import MenuMobile from "./MenuMobile.jsx";

import { MdOutlineSpaceDashboard } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { RiOrderPlayFill } from "react-icons/ri";
import { IoExitOutline, IoEnterOutline } from "react-icons/io5";
import { ImProfile } from "react-icons/im";
import { SiGooglemessages } from "react-icons/si";
import { HiMenu } from "react-icons/hi";
import { BiHomeSmile } from "react-icons/bi";
import { useLocation } from "react-router-dom";
function changeUserInformation(newInfo) {}
const Header = ({ backButtonPressed }) => {
  const location = useLocation();
  const User = useAuthentication();
  const auth = useContext(WithAuthentication);
  const {
    shoppingData,
    updateCartItem,
    cartItem,
    isLoading,
    setIsLoading,
    setProductUpdater,
    cartItemCounter,
    setCountUpdater,
    cartItemsCount,
  } = useContext(CartItemsContext);
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();
  const [showDropDown, setShowDropDown] = useState(false);
  useEffect(() => {
    setShowDropDown(false);
    setIsOpen(false);
  }, [backButtonPressed]);
  useEffect(() => {
    width > 700 && setIsOpen(false);
  }, [width]);
  const links = [
    {
      name: "خانه",
      href: "/",
      id: 1,
      icon: <BiHomeSmile className="me-1" />,
    },
  ];
  const navigation = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [mainShopAdmin, setMainShopAdmin] = useState(false);
  const [selectedProfileItem, setSelectedProfileItem] = useState(-1);
  const [profileLst, setProfileLst] = useState({
    admin: [
      // {
      //   name: "داشبورد",
      //   icon: <MdOutlineSpaceDashboard className="me-1" />,
      //   link: "admin-panel/dashboard",
      //   type: "link",
      //   id: 3,
      // },
      // {
      //   name: "سفارشات",
      //   icon: <RiOrderPlayFill className="me-1" />,
      //   link: "admin-panel/orders",
      //   type: "link",
      //   id: 4,
      // },
      // {
      //   name: "امورمالی",
      //   icon: <FaRegMoneyBillAlt className="me-1" />,
      //   link: "admin-panel/financial",
      //   type: "link",
      //   id: 5,
      // },
      // {
      //   name: "مدیریت کاربران",
      //   icon: <HiOutlineUsers className="me-1" />,
      //   link: "admin-panel/user-management",
      //   type: "link",
      //   id: 6,
      // },
      // {
      //   name: "مدیریت سرنخ",
      //   icon: <HiOutlineUsers className="me-1" />,
      //   link: "admin-panel/clue-management",
      //   type: "link",
      //   id: 7,
      // },
      // {
      //   name: "مدیریت کمپین",
      //   icon: <HiOutlineUsers className="me-1" />,
      //   link: "admin-panel/campain-management",
      //   type: "link",
      //   id: 8,
      // },
      {
        name: "لیست سرنخ",
        icon: <HiOutlineUsers className="me-1" />,
        link: "admin-panel/lead-list",
        type: "link",
        id: 9,
      },
    ],
    partner: [
      {
        name: "پروفایل",
        icon: <ImProfile className="me-1" />,
        link: "customer-panel/profile",
        type: "link",
        id: 10,
      },
      {
        name: "سفارشات",
        icon: <RiOrderPlayFill className="me-1" />,
        link: "customer-panel/orders",
        type: "link",
        id: 11,
      },
      {
        name: "پیامها",
        icon: <SiGooglemessages className="me-1" />,
        link: "customer-panel/messages",
        type: "link",
        id: 12,
      },
    ],
    users: [
      // {
      //   name: "پروفایل",
      //   icon: <ImProfile className="me-1" />,
      //   link: "customer-panel/profile",
      //   type: "link",
      //   id: 20,
      // },
      // {
      //   name: "سفارشات",
      //   icon: <RiOrderPlayFill className="me-1" />,
      //   link: "customer-panel/orders",
      //   type: "link",
      //   id: 21,
      // },
      // {
      //   name: "پیامها",
      //   icon: <SiGooglemessages className="me-1" />,
      //   link: "customer-panel/messages",
      //   type: "link",
      //   id: 22,
      // },
    ],
    marketer: [
      {
        name: "سفارشات",
        icon: <RiOrderPlayFill className="me-1" />,
        link: "admin-panel/orders",
        type: "link",
        id: 30,
      },
    ],
  });
  const dropDownSection = (title, items) => {
    return (
      <Dropdown
        show={showDropDown}
        autoClose="default"
        rootCloseEvent="click"
        onClick={() => {
          setShowDropDown((prev) => !prev);
        }}
      >
        <Dropdown.Toggle className="dropdownToggle">{title}</Dropdown.Toggle>
        <Dropdown.Menu className="drop-down">
          {items.map((v, i) => (
            <Dropdown.Item
              className={`${
                // i == selectedProfileItem
                location.pathname == `/${v.link}` && "drop-down-active"
              } drop-down-item py-1`}
              onClick={() => setSelectedProfileItem(i)}
            >
              {/* {v.type == "link" && ( */}
              <NavLink to={`${v.link}`} className="d-block text-center">
                <div className="d-flex align-items-center">
                  {v.icon}
                  <span className="w-100 text-start">{v.name}</span>
                </div>
              </NavLink>
              {/* // )} */}
              {/* {v.type == "modal" && (
                <div
                  className="text-center"
                  data-bs-target={`${v.target}`}
                  data-bs-toggle="modal"
                  backdrop="static"
                >
                  <div className="d-flex align-items-center">
                    {v.icon}
                    {v.name}
                  </div>
                </div>
              )} */}
            </Dropdown.Item>
          ))}
          <Dropdown.Item>
            <NavLink to={`/`} className=" " style={{}}>
              <div
                className="user-link exit-button"
                onClick={() => {
                  auth.logout();
                  cartItemsCount("null", "0", "0");
                  updateCartItem();
                  navigation("/");
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "15px",
                  color: "black",
                }}
              >
                <IoExitOutline className="me-1" />
                خروج
              </div>
            </NavLink>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      <Container changeUserInformation={changeUserInformation} />
      <nav className="navbar navbar-expand-xl position-relative header-style">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <NavLink
              to="/"
              className="navbar-brand fw-bold"
              style={{ color: "rgb(130 143 1)" }}
            >
              GHODSFLY
              {/* <img
                src={oilLogo}
                className="rounded-pill"
                style={{ width: "80px" }}
              /> */}
            </NavLink>
            <ul>
              {width > 1200 &&
                links.map((link, index) => (
                  <li
                    key={index}
                    className={`nav-item ${
                      index == 1 ? "d-none" : "d-flex"
                    }   align-items-center`}
                  >
                    <NavLink
                      to={link.href}
                      className={`nav-link`}
                      // aria-current="page"
                      // href={link.href}
                    >
                      <div className="d-flex align-items-center text-dark mt-2">
                        {link.icon}
                        {link.name}
                      </div>
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>
          <div className="d-flex">
            <button
              className="navbar-toggler navbar-toggler-right"
              style={{ fontSize: "30px" }}
              type="button"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <HiMenu style={{ color: "#dc3545" }} />
            </button>

            <div
              className="collapse navbar-collapse  justify-content-around align-items-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav w-100 align-items-center ms-3">
                {/* {links.map((link, index) => (
                  <li
                    key={index}
                    className={`nav-item ${
                      index == 1 ? "d-none" : "d-flex"
                    }   align-items-center`}
                  >
                    <NavLink
                      to={link.href}
                      className={`nav-link`}
                      // aria-current="page"
                      // href={link.href}
                    >
                      <div className="d-flex align-items-center">
                        {link.icon}
                        {link.name}
                      </div>
                    </NavLink>
                  </li>
                ))} */}

                {!auth.isLogged ? (
                  <span
                    className=" size1 msMobile text-decoration-none text-dark"
                    style={{ cursor: "pointer", marginRight: "auto" }}
                    data-bs-toggle="modal"
                    data-bs-target="#ModalNumber"
                  >
                    <IoEnterOutline className="me-1" />
                    ورود
                  </span>
                ) : (
                  <>
                    <div
                      className="user-link msMobile"
                      style={{ marginRight: "auto", whiteSpace: "nowrap" }}
                    >
                      {/* <NavLink
                    to={`${
                      auth.userInfo.userType == 1
                        ? "/customer-panel/orders"
                        : "/admin-panel/orders"
                    }`}
                    style={{ fontSize: "15px" }}
                  >
                    {auth.userInfo.fullName == " "
                      ? "پروفایل شما"
                      : auth.userInfo.fullName}
                  </NavLink> */}
                      {auth.userInfo.userType == "0" &&
                        dropDownSection("پنل ادمین", profileLst.admin)}
                      {auth.userInfo.userType == "1" &&
                        dropDownSection("پنل کاربر", profileLst.users)}
                      {auth.userInfo.userType == "2" &&
                        dropDownSection("پنل همکار", profileLst.partner)}
                      {auth.userInfo.userType == "4" &&
                        dropDownSection("پنل بازاریاب", profileLst.marketer)}
                    </div>
                  </>
                )}

                {/* {!mainShopAdmin && auth.isLogged && ( */}
                {/* {auth.userInfo.userType == "1" ||
                (auth.userInfo.userType == "2" && ( */}

                {/* ))} */}
              </ul>
            </div>

            {/* <div className="nav-item left-side1">
              {auth.userInfo.userType == 0 || auth.userInfo.userType == 4 ? (
                ""
              ) : (
                //  ||
                //   auth.userInfo.userType != -1
                <div
                  className="nav-link "
                  data-bs-toggle="modal"
                  data-bs-target="#CartModal"
                >
                  <p className="position-relative d-inline">
                    <span
                      className="bg-danger text-light rounded-pill position-absolute px-1"
                      style={{ fontSize: 13, right: 16, top: -5 }}
                    >
                      {shoppingData?.length}
                    </span>

                    <FaShoppingCart className="fa-solid fa-cart-shopping fs-4 cursor-pointer" />
                  </p>
                  <CartModal auth={auth}></CartModal>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </nav>

      <MenuMobile
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        publicLink={links}
        mainShopAdmin={mainShopAdmin}
        // pic={
        //   auth.userInfo.userType == 1
        //     ? auth.userInfo.url
        //     : auth.userInfo.userType == 2 &&
        //       auth.userInfo?.bussinesses[0]?.logo_file_record[0]?.url
        // }
        privateLink={
          auth.userInfo.userType == 0
            ? profileLst.admin
            : auth.userInfo.userType == 1
            ? profileLst.users
            : auth.userInfo.userType == 2
            ? profileLst.partner
            : auth.userInfo.userType == 4
            ? profileLst.marketer
            : [
                {
                  name: "ورود",
                  icon: <IoEnterOutline className="me-1" />,
                  link: "",
                  type: "modal",
                  target: "#ModalNumber",
                },
              ]
        }
      />
    </>

    // <>
    //   {/* {auth.userInfo.userType == 1 && <CartModal></CartModal>} */}
    //   <CartModal auth={auth} />

    //   <nav
    //     onClick={() => setExpanded(expanded ? false : true)}
    //     className="navbar navbar-style navbar-expand-lg navbar-light  shadow"
    //   >
    //     <div className="container-fluid">
    //       {/* <NavLink
    //         className="navbar-brand"
    //         onClick={() => setExpanded(false)}
    //         to="/"
    //       ></NavLink> */}
    //       <NavLink
    //         className="nav-link"
    //         onClick={() => setExpanded(false)}
    //         to="/"
    //       >
    //         {/* <img src={logo2} style={{ width: "50px", height: "40px" }} /> */}
    //         <img
    //           src={oilLogo}
    //           className="rounded-pill"
    //           style={{ width: "80px" }}
    //         />
    //       </NavLink>

    //       {/* <Search></Search> */}
    //       <button
    //         className="navbar-toggler"
    //         type="button"
    //         // data-bs-toggle="collapse"
    //         // data-bs-target="#navbarNav"
    //         // aria-controls="navbarNav"
    //         aria-expanded={expanded ? true : false}
    //         // aria-expanded="false"
    //         // aria-label="Toggle navigation"
    //         onClick={() => setExpanded(expanded ? false : true)}
    //       >
    //         <span className="navbar-toggler-icon" />
    //       </button>
    //       <div
    //         className={`collapse navbar-collapse justify-content-end ${
    //           expanded ? "show" : ""
    //         }`}
    //         id="navbarNav"
    //       >
    //         {/* <ul className="navbar-nav navbar_links ml-auto ">
    //           <li className="nav-item">
    //             <NavLink
    //               className="nav-link"
    //               onClick={() => setExpanded(false)}
    //               to="/"
    //             >
    //               خانه
    //             </NavLink>
    //           </li>
    //           <li className="nav-item">
    //             <NavLink
    //               className="nav-link"
    //               onClick={() => setExpanded(false)}
    //               to="/store"
    //             >
    //               فروشگاه
    //             </NavLink>
    //           </li>
    //           <li className="nav-item">
    //             <NavLink
    //               className="nav-link"
    //               onClick={() => setExpanded(false)}
    //               to="/blog"
    //             >
    //               وبلاگ
    //             </NavLink>
    //           </li>
    //         </ul> */}

    //         {!auth.isLogged ? (
    //           <div
    //             data-bs-toggle="modal"
    //             data-bs-target="#ModalNumber"
    //             className=" size1 text-decoration-none text-dark mx-5 text-center mt-2"
    //             style={{ cursor: "pointer", marginRight: "auto" }}
    //           >
    //             ورود
    //           </div>
    //         ) : (
    //           <>
    //             <div
    //               className="user-link msMobile"
    //               style={{
    //                 marginRight: "auto",
    //                 whiteSpace: "nowrap"
    //               }}
    //             >
    //               {auth.userInfo.userType == "0" &&
    //                 dropDownSection("پنل ادمین", profileLst.admin)}
    //               {auth.userInfo.userType == "1" &&
    //                 dropDownSection("پنل کاربر", profileLst.users)}
    //               {auth.userInfo.userType == "2" &&
    //                 dropDownSection("پنل همکار", profileLst.partner)}
    //               {auth.userInfo.userType == "4" &&
    //                 dropDownSection("پنل بازاریاب", profileLst.marketer)}
    //               {/* <NavLink
    //                 to={`${
    //                   auth.userInfo.userType == 1
    //                     ? "/customer-panel/orders"
    //                     : "/admin-panel/orders"
    //                 }`}
    //                 className="nav-link "
    //               >
    //                 {auth.userInfo.fullName == " "
    //                   ? "کاربر عزیز"
    //                   : auth.userInfo.fullName}
    //               </NavLink> */}
    //             </div>
    //             {/* <NavLink to={`/`} className="nav-link ">
    //               <div
    //                 className="user-link exit-button"
    //                 onClick={() => {
    //                   setProductUpdater(prev => !prev);
    //                   auth.logout();
    //                   updateCartItem();
    //                 }}
    //                 style={{ cursor: "pointer" }}
    //               >
    //                 <a className="nav-link ">خروج</a>
    //               </div>
    //             </NavLink> */}
    //           </>
    //         )}
    //         {/* {auth.userInfo.userType == 1 && ()} */}
    //         <div className="text-center">
    //           <button
    //             type="button"
    //             data-bs-toggle="modal"
    //             data-bs-target="#CartModal"
    //             className="btn  mt-2 display-btn ms-3 position-relative "
    //             style={{ boxShadow: "none" }}
    //             onClick={() => {
    //               updateCartItem();
    //             }}
    //           >
    //             <p className="position-relative d-inline">
    //               <span
    //                 className="bg-danger text-light rounded-pill position-absolute px-1"
    //                 style={{ fontSize: 13, right: 16, top: -5 }}
    //               >
    //                 {shoppingData.length}
    //               </span>

    //               <FaShoppingCart className="fa-solid fa-cart-shopping fs-4" />
    //             </p>
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //     <Container changeUserInformation={changeUserInformation} />
    //   </nav>
    //   <MenuMobile
    //     isOpen={isOpen}
    //     setIsOpen={setIsOpen}
    //     // publicLink={links}
    //     mainShopAdmin={mainShopAdmin}
    //     // pic={
    //     //   auth.userInfo.userType == 1
    //     //     ? auth.userInfo.url
    //     //     : auth.userInfo.userType == 2 &&
    //     //       auth.userInfo?.bussinesses[0]?.logo_file_record[0]?.url
    //     // }
    //     privateLink={
    //       auth.userInfo.userType == 0
    //         ? profileLst.superadmin
    //         : auth.userInfo.userType == 1
    //         ? profileLst.profile
    //         : auth.userInfo.userType == 2
    //         ? profileLst.shop
    //         : [
    //             {
    //               name: "ورود",
    //               icon: <IoEnterOutline className="me-1" />,
    //               link: "",
    //               type: "modal",
    //               target: "#ModalNumber"
    //             }
    //           ]
    //     }
    //   />
    // </>
  );
};

export default Header;
