import CartModal from "common/order/CartModal";

import { RiWallet3Line } from "react-icons/ri";
import { IoExitOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";

import { NavLink } from "react-router-dom";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import { useContext } from "react";
import { useState } from "react";
import logo from "assets/images/sitelogo.svg";
import { HiUser } from "react-icons/hi";
import CartItemsContext from "context/CardItemsProvider";
import oilLogo from "assets/images/Logos/New Project.png";

export default function MenuMobile({
  isOpen,
  publicLink,
  privateLink,
  setIsOpen,
  mainShopAdmin,
  pic,
}) {
  const auth = useContext(WithAuthentication);
  const [selectedItem, setSelectedItem] = useState(-1);
  const {
    items,
    setItems,
    editCartItems,
    // updateCartItemsFunc,
    updateCartItem,
    length,
    cartItemCounter,
    setCountUpdater,
    cartItemsCount,
  } = useContext(CartItemsContext);
  return (
    <div>
      {isOpen && (
        <div className="menu-mobile shadow pb-5">
          <div className="text-end">
            <AiOutlineClose
              className="me-1 mt-1"
              style={{ width: 20, height: 20 }}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>

          <div className="text-center my-2">
            {auth.userInfo.userType == 0 ? (
              <div>
                GHODSFLY
                {/* <img src={oilLogo} alt="logo" style={{ width: 70 }} /> */}
              </div>
            ) : (
              <div>
                {pic == undefined || pic == "" ? (
                  <HiUser style={{ width: 80, height: 80 }} />
                ) : (
                  <img
                    className="rounded-pill"
                    src={`http://marketgroup.tatdemo.ir/${pic}`}
                    style={{ width: 80, height: 80 }}
                  />
                )}
                {/* <div>
                  {auth.userInfo.userType == 1
                    ? auth.userInfo.fullName != ""
                      ? `${auth.userInfo.fullName} عزیز`
                      : " کاربر عزیز"
                    : auth.userInfo.userType == 2
                    ? `مدیر محترم ${auth.userInfo.bussinesses[0].title} `
                    : auth.userInfo.userType == -1 && " کاربر عزیز"}
                  به ghodsfly خوش آمدید.
                </div> */}
              </div>
            )}
          </div>

          <div className="position-relative ul-container   my-2">
            {!mainShopAdmin && auth.isLogged && (
              <div className="left-side1 nav-item">
                <div className="nav-link">
                  <CartModal className=" li" />
                </div>
              </div>
            )}

            {/* <div
              className="nav-item left-side1 li"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <div
                className="nav-link "
                
              >
                <CartModal></CartModal>
               
              </div>
            </div> */}
            {/* <li>
              <NavLink to="/">
                <div className="nav-link text-dark size1">
                  <span style={{ fontSize: 14 }} className="mx-1 ">
                    کیف پول شما
                  </span>
                  
                  <RiWallet3Line style={{ fontSize: "18px" }}></RiWallet3Line>
                </div>
              </NavLink>
            </li> */}
            {/* public links */}
            {publicLink.map((pl) => {
              if (pl.type == "modal") {
                return (
                  <div
                    className={`li ${selectedItem == pl.id && "active-item"}`}
                    data-bs-target={`${pl.target}`}
                    data-bs-toggle="modal"
                    onClick={() => {
                      setIsOpen(false);
                      setSelectedItem(pl.id);
                    }}
                  >
                    {pl.name}
                  </div>
                );
              } else if (pl.id != 2) {
                return (
                  <NavLink
                    to={`${pl.href}`}
                    className={`li ${selectedItem == pl.id && "active-item"}`}
                    onClick={() => {
                      setIsOpen(false);
                      setSelectedItem(pl.id);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {pl.icon} {pl.name}
                    </div>
                  </NavLink>
                );
              } else if (
                pl.id == 2 &&
                auth.isLogged &&
                auth.userInfo.userType != "0"
              ) {
                return (
                  <NavLink
                    to={`${pl.href}`}
                    className={`li ${selectedItem == pl.id && "active-item"}`}
                    onClick={() => {
                      setIsOpen(false);
                      setSelectedItem(pl.id);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {pl.icon} {pl.name}
                    </div>
                  </NavLink>
                );
              }
            })}

            {/* {auth.userInfo.userType != -1 && (
              <div className="mt-3 mb-2  rounded py-1 text-center panel-title">
                پنل کاربر
              </div>
            )} */}
            {/* user panel links */}
            {privateLink.map((vl) => {
              if (vl.type == "modal") {
                return (
                  <div
                    className={`li ${selectedItem == vl.id && "active-item"}`}
                    data-bs-target={`${vl.target}`}
                    data-bs-toggle="modal"
                    onClick={() => {
                      setIsOpen(false);
                      setSelectedItem(vl.id);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {" "}
                      {vl.icon} {vl.name}
                    </div>
                  </div>
                );
              } else {
                return (
                  <NavLink
                    to={`${vl.link}`}
                    className={`li ${selectedItem == vl.id && "active-item"}`}
                    onClick={() => {
                      setIsOpen(false);
                      setSelectedItem(vl.id);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {vl.icon}
                      {vl.name}
                    </div>
                  </NavLink>
                );
              }
            })}
            {/* exit button */}
            {auth.userInfo.userType != -1 && (
              <NavLink
                to="/"
                className="li"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <div
                  className="user-link exit-button "
                  onClick={() => {
                    auth.logout();
                    // updateCartItemsFunc();
                    async function update() {
                      await updateCartItem();
                      cartItemsCount("null", "0", "0");
                    }
                    update();
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "15px",
                    color: "black",
                  }}
                >
                  <IoExitOutline className="me-1" />
                  خروج
                </div>
              </NavLink>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
