import AdminSidebar from "./AdminSidebar";
import { useState } from "react";
export default function AdminLayout({ children }) {
  return (
    <div dir="rtl" className="d-flex " style={{ height: "auto" }}>
      {/* <AdminSidebar></AdminSidebar> */}
      <div className="pt-4 pb-4" style={{ width: "100%" }}>
        {children}
      </div>
    </div>
  );
}
