import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { addCommas } from "@persian-tools/persian-tools";

import { FiEdit } from "react-icons/fi";
import { FaMinus, FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import {
  BsFillPlusSquareFill,
  BsFileMinusFill,
  BsCartPlus,
} from "react-icons/bs";
import { AiFillMinusSquareFill } from "react-icons/ai";
import sendRequest from "hooks/useAxios";
// amazing product text
import text from "../../assets/images/63494995.png";
import { useEffect, useState } from "react";
// start of slider
import { NavLink } from "react-router-dom";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import { useContext } from "react";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import { CgUnavailable } from "react-icons/cg";
import { RiDeleteBin4Line } from "react-icons/ri";
import { ImInfo } from "react-icons/im";
import { TiTickOutline } from "react-icons/ti";
import MoreInfoProduct from "./MoreInfoProduct";
import TinySlider from "./TinySlider";
import AddLead from "components/AddLead/AddLead";
export default ({ products, cover, isAdmin }) => {
  const [amazingProduct, setAmazingProduct] = useState([]);
  useEffect(() => {
    sendRequest(
      "Find_products",

      {
        tbl_name: `left join,files,coverimg_fileid,id`,
        conditions: `main_discount/!=/0@@main_discount_endtime/>/'${Math.floor(
          new Date() / 1000
        )}'`,
      }
    ).then(({ data }) => {
      setAmazingProduct(data);
    });
  }, []);

  return (
    amazingProduct.length > 0 && (
      <div className="products mb-5 position-relative">
        {/* {isAdmin && (
        <div
          className="position-absolute edit-icon text-light p-1"
          style={{
            backgroundColor: "navy",
            right: "10px",
            zIndex: "10",
            fontSize: "14px",
          }}
        >
          <FiEdit className="mx-1 "></FiEdit>
          ویرایش
        </div>
      )} */}
        {/* start of slider */}
        <Swiper
          dir="rtl"
          spaceBetween={10}
          // centeredSlides={true}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            1200: {
              slidesPerView: 4.5,
              spaceBetween: 20,
            },
            800: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            // 700: {
            //   slidesPerView: 3,
            //   spaceBetween: 20,
            // },
            400: {
              slidesPerView: 2.5,
              spaceBetween: 5,
            },
            320: {
              slidesPerView: 2.5,
              spaceBetween: 5,
            },
          }}
        >
          {/*start of first slide that different than others */}
          <SwiperSlide className="my-4 mx-0 text-light">
            <div className="d-flex flex-column align-items-center">
              <img className="my-3 justify amazing-text" src={text}></img>
              <img src={cover} className="my-3 amazing-image"></img>
            </div>
          </SwiperSlide>
          {/*end of first slide that different than others */}
          {amazingProduct.map((value) => {
            return (
              <SwiperSlide
                className="swiper-slides my-4"
                style={{ borderRadius: "12px" }}
              >
                {/* content of slider */}
                <NavLink
                  to={`/product/${value.id}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    width: "100%",
                  }}
                >
                  <Slide productDetail={value} isAdmin={isAdmin}></Slide>
                </NavLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* end of slider */}
      </div>
    )
  );
};
// end of slider

// start of slides content
function Slide({
  productDetail,
  type,
  isAdmin,
  selectedEditProduct,
  setSelectedEditProduct,
  cartItem,
  updater,
  setUpdater,
  cartItemsCount,
  setShowAddLeadModal,
  setSelectedProduct,
}) {
  // const a =
  //   100 -
  //   Math.floor((productDetail.main_discount / productDetail.main_price) * 100);
  const auth = useContext(WithAuthentication);
  const a = Math.floor(
    (productDetail.main_discount / productDetail.main_price) * 100
  );
  const aPartner = Math.floor(
    (productDetail.partners_discount / productDetail.partners_price) * 100
  );
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [shoppingDisable, setShoppingDisable] = useState(false);
  // useEffect(() => {
  //   if (auth.userInfo.userType == 0) {
  //     setShoppingDisable(true);
  //   }
  // }, [auth.userInfo.userType]);
  let shoppingDisable =
    auth.userInfo.userType == "0" || auth.userInfo.userType == "4"
      ? true
      : false;
  // countdown variables
  const startTime = new Date().getTime() / 1000;
  const endTime = productDetail.main_discount_endtime;
  const endTimePartner = productDetail.partner_discount_endtime;
  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;

  const timerProps = {
    isPlaying: true,
    strokeWidth: 6,
  };
  const deleteProduct = () => {
    Swal.fire({
      icon: "warning",
      title: "آیا از حذف این محصول مطمئن هستید؟",
      showCancelButton: true,
      cancelButtonText: "انصراف",
      confirmButtonText: "حذف",
      cancelButtonColor: "#EF394E",
      confirmButtonColor: "#439B58",
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        sendRequest("deleteproduct", { productId: productDetail.id }).then(
          ({ data }) => {
            if (data.status == 1) {
              setUpdater((prev) => !prev);
              Swal.fire({
                title: "محصول با موفقیت حذف شد.",
                showConfirmButton: false,
                timer: 2000,
              });
              // navigate("/store", { replace: true });
            }
          }
        );
      }
    });
  };

  const disableProduct = () => {
    Swal.fire({
      title: "آیا از غیرفعال کردن محصول اطمینان دارید؟",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "حذف",
      cancelButtonText: "انصراف",
    }).then((result) => {
      // if (result.isConfirmed) {
      //   sendRequest("deletepost", { postId: postId }).then(({ data }) => {
      //     if (data.status == 1) {
      //       Swal.fire("محصول با موفقیت غیر فعال شد.");
      //       navigation("/blog");
      //     } else {
      //       Swal.fire("مشکلی در غیرفعال کردن محصول پیش آمده است.");
      //     }
      //   });
      // }
    });
  };
  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
  const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;
  // end of countdoown variables

  const priceCondition = () => {
    if (auth.userInfo.userType != 2) {
      if (productDetail.main_discount != "0") {
        if (
          (productDetail.main_discount_endtime != "0" &&
            endTime >= startTime) ||
          // !productDetail.main_discount_endtime
          productDetail.main_discount_endtime == "0"
        ) {
          {
            return discountContainer;
          }
        } else if (
          productDetail.main_discount_endtime != "0" &&
          endTime < startTime
        ) {
          return <>{addCommas(productDetail.main_price)} ت</>;
        }
      } else {
        return <>{addCommas(productDetail.main_price)}ت</>;
      }
    } else {
      if (productDetail.partners_discount != "0") {
        if (
          ((productDetail.partner_discount_endtime != "0" ||
            productDetail.partner_discount_endtime != "undefined") &&
            endTimePartner >= startTime) ||
          // !productDetail.partner_discount_endtime
          productDetail.partner_discount_endtime == "0" ||
          productDetail.partner_discount_endtime == "undefined"
        ) {
          {
            return discountContainerPartner;
          }
        } else if (
          (productDetail.partner_discount_endtime != "0" ||
            productDetail.partner_discount_endtime != "undefined") &&
          // !productDetail.partner_discount_endtime ||
          endTimePartner < startTime
        ) {
          return <>{addCommas(productDetail.partners_price)} ت</>;
        }
      } else {
        return <>{addCommas(productDetail.partners_price)}ت</>;
      }
    }
  };
  const discountContainer = (
    // productDetail.main_discount != "0" ? (
    <div className="d-flex flex-wrap">
      <div className=" col-12  col-lg-6">
        <del>{addCommas(productDetail.main_price)}</del>
      </div>

      <div className=" col-12  col-lg-6">
        <ins style={{ textDecoration: "none", color: "red" }}>
          {productDetail.main_discount
            ? `${addCommas(
                +productDetail.main_price - +productDetail.main_discount
              )} ت`
            : ""}
        </ins>
      </div>
    </div>
  );
  const discountContainerPartner = (
    // productDetail.main_discount != "0" ? (
    <div className="d-flex flex-wrap">
      <div className=" col-12  col-lg-6">
        <del>{addCommas(productDetail.partners_price)}</del>
      </div>

      <div className=" col-12  col-lg-6">
        <ins style={{ textDecoration: "none", color: "red" }}>
          {productDetail.partners_discount
            ? `${addCommas(
                +productDetail.partners_price - +productDetail.partners_discount
              )} ت`
            : ""}
        </ins>
      </div>
    </div>
  );
  // ) : (
  //   <div className="d-flex flex-wrap">
  //     <div className="me-3">{addCommas(productDetail.main_price)}تومان</div>
  //   </div>
  // );
  const discountSection = (
    <div className="justify-content-center d-flex">
      <div className="">
        {/* count down */}
        <App></App>
      </div>
      <div className=" mx-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.1"
          height="23.9"
          viewBox="0 0 25.1 23.9"
        >
          <g
            id="Icon_feather-clock"
            data-name="Icon feather-clock"
            transform="translate(-2.5 -2.5)"
          >
            <path
              id="Path_228"
              data-name="Path 228"
              d="M27.1,14.45c0,6.324-5.395,11.45-12.05,11.45S3,20.774,3,14.45,8.395,3,15.05,3,27.1,8.126,27.1,14.45Z"
              fill="none"
              stroke="#707070"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <path
              id="Path_229"
              data-name="Path 229"
              d="M18,9V21.075L24,25.1"
              transform="translate(-3.688 -4.2)"
              fill="none"
              stroke="#707070"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
          </g>
        </svg>
      </div>
    </div>
  );
  const percentCondition = () => {
    if (
      (productDetail.main_discount_endtime != "0" && endTime >= startTime) ||
      (!productDetail.main_discount_endtime == "0" &&
        productDetail.main_discount != "0")
    ) {
      {
        return percent;
      }
    }
    if (
      (productDetail.partner_discount_endtime && endTime >= startTime) ||
      (!productDetail.partner_discount_endtime &&
        productDetail.partners_discount != "0")
    ) {
      {
        return percent;
      }
    }
  };
  const percent = (
    <div>
      {auth.userInfo.userType != 2 ? (
        <div
          style={{ height: "28px", width: "28px", top: "10px", right: "10px" }}
          className="position-absolute "
        >
          {a > 0 && (
            <div
              className="rounded-pill bg-danger text-center percent text-light d-flex align-items-center justify-content-center h-100"
              style={{ fontSize: 12 }}
            >
              {a > 0 ? `%${a}` : " "}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{ height: "28px", width: "28px", top: "10px", right: "10px" }}
          className="position-absolute "
        >
          {aPartner > 0 && (
            <div
              className="rounded-pill bg-danger text-center percent text-light d-flex align-items-center justify-content-center h-100"
              style={{ fontSize: 12 }}
            >
              {`%${aPartner}`}
            </div>
          )}
        </div>
      )}
    </div>
  );

  // start of count down component
  function App() {
    const remainingTime = endTime - startTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;
    // partner discount time
    const remainingTimePartner = endTimePartner - startTime;
    const daysPartner = Math.ceil(remainingTimePartner / daySeconds);
    const daysDurationPartner = daysPartner * daySeconds;
    return (
      <div>
        {auth.userInfo.userType != 2 ? (
          <div
            className="App d-flex align-items-center w-100"
            style={{ width: "10px", color: "darkgray" }}
          >
            <CountdownCircleTimer
              {...timerProps}
              colors="darkgray"
              size="20px"
              duration={minuteSeconds}
              initialRemainingTime={remainingTime % minuteSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > 0,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeSeconds(elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
            :
            <CountdownCircleTimer
              {...timerProps}
              colors="darkgray"
              size="20px"
              duration={hourSeconds}
              initialRemainingTime={remainingTime % hourSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeMinutes(hourSeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
            :
            <CountdownCircleTimer
              {...timerProps}
              colors="darkgray"
              size="20px"
              duration={daySeconds}
              initialRemainingTime={remainingTime % daySeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > hourSeconds,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeHours(daySeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
            :
            <CountdownCircleTimer
              {...timerProps}
              size="20px"
              colors="darkgray"
              duration={daysDuration}
              initialRemainingTime={remainingTime}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeDays(daysDuration - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
        ) : (
          <div
            className="App d-flex align-items-center w-100"
            style={{ width: "10px", color: "darkgray" }}
          >
            <CountdownCircleTimer
              {...timerProps}
              colors="darkgray"
              size="20px"
              duration={minuteSeconds}
              initialRemainingTime={remainingTimePartner % minuteSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTimePartner - totalElapsedTime > 0,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeSeconds(elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
            :
            <CountdownCircleTimer
              {...timerProps}
              colors="darkgray"
              size="20px"
              duration={hourSeconds}
              initialRemainingTime={remainingTimePartner % hourSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat:
                  remainingTimePartner - totalElapsedTime > minuteSeconds,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeMinutes(hourSeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
            :
            <CountdownCircleTimer
              {...timerProps}
              colors="darkgray"
              size="20px"
              duration={daySeconds}
              initialRemainingTime={remainingTimePartner % daySeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat:
                  remainingTimePartner - totalElapsedTime > hourSeconds,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeHours(daySeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
            :
            <CountdownCircleTimer
              {...timerProps}
              size="20px"
              colors="darkgray"
              duration={daysDurationPartner}
              initialRemainingTime={remainingTimePartner}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(getTimeDays(daysDurationPartner - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
        )}
      </div>
    );
  }
  // end of count down component
  // const auth = useContext(WithAuthentication);
  console.log("test", productDetail);
  return (
    <div
      className="slide-item card shadow amazing-product w-100 d-flex flex-column"
      style={{ borderRadius: "12px" }}
    >
      {/* <div>{percentCondition()}</div> */}
      <div className="product-img card-img-top text-center">
        <div>
          <TinySlider
            array={[
              productDetail.url,
              ...productDetail?.otherMedia.map((v) => v.url),
            ]}
          />
          {/* <img
            src={`https://ghodsfly.ir/data/${productDetail.url}`}
            // className="w-100"
            // className="product-img card-img-top position-relative"
            style={{ borderRadius: "12px", width: "100%", height: "240px" }}
          ></img> */}
        </div>
        {isAdmin && (
          <div className="d-flex w-100 justify-content-center  edit-style">
            <button
              className="btn rounded-0"
              // style={{ bottom: "0" }}
              data-bs-toggle="modal"
              data-bs-target="#editProductModalUnique"
              onClick={() => {
                setSelectedEditProduct(productDetail);
                // console.log(productDetail);
              }}
              data-tip="ویرایش محصول"
            >
              <ReactTooltip />
              <FaRegEdit />
            </button>
            {/* <div>
              <button
                className=" btn rounded-0"
                onClick={deleteProduct}
                data-tip="حذف کردن محصول"
              >
                <RiDeleteBin4Line />
              </button>
            </div>
            <div>
              <button
                className="btn rounded-0"
                onClick={disableProduct}
                data-tip="غیر فعال کردن محصول"
              >
                <CgUnavailable />
              </button>
            </div> */}
            {/* <div>
              <button
                className="btn rounded-0"
                onClick={disableProduct}
                data-tip="فعال کردن محصول"
              >
                <TiTickOutline />
              </button>
            </div> */}
          </div>
        )}
      </div>

      <div className=" mx-1 d-flex flex-column  flex-grow-1 justify-self-end">
        {/* title */}
        <div
          className=" d-flex justify-content-between align-items-start"
          id="#one-line"
        >
          <div className="four-line product-title mt-1">
            {productDetail?.description}
          </div>

          <MoreInfoProduct
            product={selectedEditProduct}
            auth={auth}
            showMoreInfoModal={showMoreInfoModal}
            setShowMoreInfoModal={setShowMoreInfoModal}
            setShowModal={setShowModal}
          />
        </div>
        <AddLead
          showModal={showModal}
          setShowModal={setShowModal}
          selectedProduct={productDetail}
        />
        <div
          style={{ fontSize: 14, color: "darkorchid", height: 60 }}
          className="text-center "
        >
          برای گرفتن پاسخ فوری تمام سوالات خود در مورد این محصول لطفا با شماره
          <a
            href="tel:09111714096"
            className="text-decoration-none"
            style={{ color: "darkorchid" }}
          >
            09111714096
          </a>{" "}
          تماس بگیرید.
        </div>
        {productDetail?.inventory_count > 0 ? (
          <div
            style={{ fontSize: 14, color: "crimson", height: 30 }}
            className="text-center blink "
          >
            این کالا برای تحویل فوری موجود می باشد.
          </div>
        ) : (
          <div style={{ fontSize: 14, color: "crimson", height: 30 }}></div>
        )}
        <div
          style={{ fontSize: 12, color: "cornsilk" }}
          className="text-center"
        >
          باتوجه به نوسانات نرخ ارز برای اطلاع از قیمت کالا لطفا تماس بگیرید.
        </div>
        {/* rate */}
        {/* <div className="my-2 product-rate" style={{ textAlign: "left" }}>
          ({productDetail.rating_count}){productDetail.avg_rating}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="19"
            viewBox="0 0 15 19"
          >
            <path
              id="Icon_awesome-star"
              data-name="Icon awesome-star"
              d="M8.137.66,6.306,5.573l-4.1.79c-.735.141-1.029,1.339-.5,2.026l2.964,3.822-.7,5.4c-.126.976.65,1.707,1.3,1.25l3.664-2.549,3.664,2.549c.65.453,1.427-.275,1.3-1.25l-.7-5.4,2.964-3.822c.533-.686.238-1.885-.5-2.026l-4.1-.79L9.746.66a.82.82,0,0,0-1.609,0Z"
              transform="translate(-1.441 0.001)"
              fill="#eca72c"
            />
          </svg>
        </div> */}
        {/* price */}
        {/* <div className="mb-2 mt-1 product-price fw-bold">
          {priceCondition()}
        </div> */}
        {/* counter */}
        {/* <div className="product-discount">
          {auth.userInfo.userType != 2
            ? endTime > startTime
              ? discountSection
              : null
            : endTimePartner > startTime
            ? discountSection
            : null}
        </div> */}
        {/* {type == "hashtags" && (
          <div
            className=" d-flex justify-content-center align-items-center"
            style={{ height: 30 }}
          >
            {productDetail.cartItem_count != 0 ? (
              <>
                <div className="bg-danger rounded">
                  <FaPlus
                    className="m-1"
                    onClick={() => {
                      cartItem("increase", productDetail.id);
                    }}
                    style={{
                      width: 15,
                      height: 15,
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <span className="px-2">{productDetail.cartItem_count}</span>

                {productDetail.cartItem_count == "1" && (
                  <div className="bg-danger rounded ">
                    <FaRegTrashAlt
                      className="m-1"
                      onClick={() => {
                        cartItem("decrease", productDetail.id);
                        cartItemsCount("value", `pid${productDetail.id}`, "0");
                      }}
                      style={{
                        width: 15,
                        height: 15,
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {productDetail.cartItem_count != "1" && (
                  <div
                    className={` rounded ${
                      productDetail.cartItem_count == "0"
                        ? "bg-secondary"
                        : "bg-danger"
                    }`}
                  >
                    <FaMinus
                      className="m-1"
                      onClick={() => {
                        cartItem("decrease", productDetail.id);
                      }}
                      style={{
                        width: 15,
                        height: 15,
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <button
                  className="btn p-0 bg-danger rounded"
                  onClick={() => {
                    cartItem("increase", productDetail.id);
                  }}
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target={`${!auth.isLogged && "#ModalNumber"}`}
                  disabled={shoppingDisable}
                >
                  <BsCartPlus
                    className="m-1"
                    style={{
                      width: 20,
                      height: 20,
                      color: "white",
                    }}
                  />{" "}
                  <span className="ms-1 me-2 text-light">خرید</span>
                </button>{" "}
              </>
            )}
          </div>
        )} */}
        <div className="mt-auto mb-2">
          {" "}
          <div
            className=" px-1 pt-0 mb-1 moreinfo cursor-pointer text-center rounded"
            data-tip="اطلاعات بیشتر"
            onClick={() => {
              setSelectedEditProduct(productDetail);
              setShowMoreInfoModal(true);
            }}
            // data-bs-target="#moreInfoProductModal"
            // data-bs-toggle="modal"
            style={{ backgroundColor: "white" }}
          >
            {" "}
            <ImInfo />
            <span className="mx-1 "> اطلاعات بیشتر</span>
          </div>
          <div className="text-center">
            <button
              className=" btn buy-once fw-bold fs-5"
              onClick={() => {
                setShowAddLeadModal(true);
                setSelectedProduct(productDetail);
              }}
            >
              خرید با یک کلیک!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
// end of slides content
export { Slide };
