import { createContext, useState, useEffect, useContext } from "react";
import sendRequest2 from "hooks/useAxios2";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import sendRequest from "hooks/useAxios";
import toast from "react-hot-toast";

const CartItemsContext = createContext({
  shoppingData: [], //list of items
  updateCartItem: () => {},
  cartItem: () => {},
  isLoading: "",
  setIsLoading: () => {},
  productUpdater: "",
  setProductUpdater: () => {},
  cartItemCounter: {},
  setCountUpdater: () => {},
  cartItemsCount: () => {},
});
export default CartItemsContext;
export function CartItemsProvider({ children }) {
  const auth = useContext(WithAuthentication);
  const [shoppingData, setShoppingData] = useState([]);
  const [cartItemUpdater, setCartItemUpdater] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [productUpdater, setProductUpdater] = useState(false);
  const [cartItemCounter, setCartItemCounter] = useState({});

  const [countUpdater, setCountUpdater] = useState(false);
  const updateCartItem = () => {
    setCartItemUpdater((prev) => !prev);
    setCountUpdater((prev) => !prev);
  };

  useEffect(() => {
    // auth.userInfo.userType != -1 &&
    sendRequest2("get_cart_items").then((data) => {
      setShoppingData(data.data.cart_items);
      console.log("******get cart");
    });
    // sendRequest("getcartitem").then(data => {
    //   // setShoppingData(data.data.cart_items);
    //   setShoppingData(data.data.items);
    // });
  }, [cartItemUpdater]);

  useEffect(() => {
    let temp = {};

    for (let i = 0; i < shoppingData?.length; i++) {
      temp = {
        ...temp,
        [`pid${shoppingData[i].product_id}`]: shoppingData[i].itemcount,
      };
    }
    setCartItemCounter(temp);
  }, [countUpdater, shoppingData]);
  const cartItemsCount = (type, name, value) => {
    if (type == "value") {
      setCartItemCounter((prev) => {
        return { ...prev, [name]: value };
      });
      setCountUpdater((prev) => !prev);
    } else if (type == "null") {
      console.log("******", type, name, value);
      setCartItemCounter({});
      setCountUpdater((prev) => !prev);
    }
  };
  const cartItem = (type, productId) => {
    setIsLoading(true);
    let result = false;
    let temp = { product_id: productId };
    if (type == "increase") {
      temp = { ...temp, action_code: 1 };
    } else if (type == "decrease") {
      temp = { ...temp, action_code: -1 };
    }
    sendRequest("manage_cart_item", temp).then((data) => {
      if (data.data.status == 1) {
        result = true;
        type == "increase" &&
          toast.success("محصول با موفقیت به سبد خرید شما اضافه شد.");
        type == "decrease" &&
          toast.success("محصول با موفقیت از سبد خرید شما کم شد.");

        updateCartItem();
        setIsLoading(false);
      } else if (data.data.status == -4) {
        toast.error("موجودی این محصول به پایان رسیده است.");
        setIsLoading(false);
      } else {
        result = false;
        type == "increase" &&
          auth.isLogged &&
          toast.error(
            "متاسفانه مشکلی درافزودن محصول به سبد خرید پیش آمده است."
          );
        type == "decrease" &&
          toast.error("متاسفانه مشکلی در کاهش محصول از سبد خرید پیش آمده است.");
        setIsLoading(false);
      }
    });
    return result;
  };
  return (
    <>
      <CartItemsContext.Provider
        value={{
          shoppingData,
          updateCartItem,
          cartItem,
          isLoading,
          setIsLoading,
          productUpdater,
          setProductUpdater,
          cartItemCounter,
          setCountUpdater,
          cartItemsCount,
        }}
      >
        {children}
      </CartItemsContext.Provider>
    </>
  );
}
