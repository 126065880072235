import { useState } from "react";
import Cookies from "js-cookie";
import AuthenticationContext from "./AuthenticationContext.ts";
import { useEffect, useContext } from "react";
import { USER_TYPE } from "constants/constants.js";
import toast from "react-hot-toast";
import AuthContext from "Authentication/AuthenticationContext.ts";

import sendRequest from "hooks/useAxios";
// admin access level: [5, 7, 8, 9, 10, 11, 12]
// customer access level : [13,14,15,16]
interface UserInformation {
  token: string;
  fullName: string;
  userType: number;
  mobile: number;
  userName: string;
  accessLevel: number[];
  id: any;
  isFirst: boolean;
}
export default function Authentication({
  children,
}: {
  children: JSX.Element;
}) {
  const auth = useContext(AuthContext);

  const [userInfo, setUserInfo] = useState<UserInformation>({
    token: "",
    fullName: "",
    userType: -1,
    mobile: 0,
    userName: "",
    accessLevel: [],
    id: null,
    isFirst: true,
  });

  const [isLogged, setIsLogged] = useState<boolean>(false);

  useEffect(() => {
    checkCookie();
  }, []);

  function login(
    token: string,
    fullName: string,
    userType: number,
    mobile: number,
    userName: string,
    accessLevel: any[],
    id: any
  ): void {
    if (token) {
      // if (userType == 1) {
      //   accessLevel = [13, 14, 15, 16];
      // }
      setUserInfo({
        token,
        fullName,
        userType: userType,
        mobile,
        userName,
        accessLevel,
        id: id,
        isFirst: false,
      });

      setIsLogged(true);
      Cookies.set("token", token);
    }
  }

  function logout(): void {
    setUserInfo({
      token: "",
      fullName: "",
      userType: -1,
      mobile: 0,
      userName: "",
      accessLevel: [],
      id: null,
      isFirst: false,
    });
    setIsLogged(false);
    Cookies.remove("token");
  }

  function checkCookie(): void {
    if (Cookies.get("token")) {
      sendRequest("checklogininfo", { usertoken: Cookies.get("token") }).then(
        ({ data }) => {
          if (data.status == -1) {
            toast.error("کاربری با این مشخصات یافت نشد.");
            Cookies.remove("token");

            return "";
          }

          // let userType;
          // if (value.userinfo[0].isadmin == 1) {
          //   switch (+value.userinfo[0].user_admin_group) {
          //     case 1:
          //       userType = USER_TYPE.generalAdmin;
          //       break;
          //     case 2:
          //       userType = USER_TYPE.tourLeader;
          //       break;
          //     case 3:
          //       userType = USER_TYPE.accountants;
          //       break;
          //     case 4:
          //       userType = USER_TYPE.saleCounter;
          //       break;
          //     default:
          //       break;
          //   }
          // } else {
          //   userType = USER_TYPE.customer;
          // }
          let userType;
          login(
            data.data.usertoken,
            data.userinfo[0].fname + " " + data.userinfo[0].lname,
            +data.userinfo[0].type,
            data.userinfo[0].mobile,
            "",
            [],
            +data.userinfo[0].id
          );
        }
      );
    } else {
      setUserInfo({
        token: "",
        fullName: "",
        userType: -1,
        mobile: 0,
        userName: "",
        accessLevel: [],
        id: null,
        isFirst: false,
      });
    }
  }

  return (
    <>
      <AuthenticationContext.Provider
        value={{
          login,
          userInfo,
          isLogged,
          logout,
        }}
      >
        {children}
      </AuthenticationContext.Provider>
    </>
  );
}
