import Carousel from "react-bootstrap/Carousel";
import "./tiny-slider.scss";

function TinySlider({ array }) {
  return (
    <Carousel indicators={false} className="tiny-slider">
      {array.map((value) => {
        return (
          <Carousel.Item interval={5000}>
            <img
              className="d-block w-100"
              src={`https://ghodsfly.ir/data/${value}`}
              alt="First slide"
              style={{
                borderRadius: "12px",
                width: "100%",
                height: "240px",
                objectFit: "contain",
              }}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default TinySlider;
