import { NavLink, Link } from "react-router-dom";
import { articleDataToQuery } from "../../utils/helperFunctions";
import { useEffect, useState } from "react";

function BlogKnowable({ blogHashtags }) {
  const [articleData, setArticleData] = useState({
    title: "",
    tags: [],
    dateRange: [],
    toggleDisplay: true,
  });

  return (
    <div
      id="blogknow-img"
      className=" mx-0 mt-5 mb-3"
      style={{ padding: "10px 5%" }}
    >
      <div>
        <h4 className="pt-5 ps-5 mb-5 text-white">
          دوست دارید بیشتر راجب چه چیزی بدانید
        </h4>
        <div className="d-flex flex-wrap justify-content-center">
          {blogHashtags.map((value) => {
            return (
              <NavLink
                className="blogknow text-center p-2 my-3 mx-4 bg-light rounded-10 pointer text-decoration-none text-dark"
                style={{ width: "130px" }}
                to={`/${articleDataToQuery(
                  {
                    title: `مطالب مرتبط با برچسب "${value.label}"`,
                    tags: [value],
                    dateRange: [],
                    toggleDisplay: true,
                  },
                  false
                )}`}
                // to={`/article/${data.id}`}
              >
                {value.label}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default BlogKnowable;
