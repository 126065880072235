// const reactDatePickerStringValueToUnix = (val) => {
//   return Date.parse(moment.from(digitsFaToEn(val), "fa", "YYYY/MM/DD").format("YYYY/MM/DD")) / 1000;
// };
const unixToPersianDate = (unix) => {
  let options = { year: "numeric", month: "numeric", day: "numeric" };
  let date = new Date(parseInt(unix) * 1000).toLocaleDateString(
    "fa-IR",
    options
  );
  date.replace(/([۰-۹])/g, (token) =>
    String.fromCharCode(token.charCodeAt(0) - 1728)
  );
  return date;
};
const maskNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const translateToReactSelect = (arrayValue, reverse) => {
  let after = [];
  if (reverse) {
    // react select to api
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      if (arrayValue[index].id) {
        obj.id = arrayValue[index].id;
      } else {
        obj.txt = arrayValue[index].label;
        obj.id = arrayValue[index].value;
      }

      after.push(obj.id);
    }
  } else {
    // api to react select
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.label = arrayValue[index].txt;
      obj.value = arrayValue[index].id;

      after.push(obj);
    }
  }
  return after;
};
const phoneNumberValidation = (phoneNumber) => {
  return /^09\d{9}$/.test(phoneNumber);
};
const objToFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};
const stringValueToReactSelect2 = (arrayValue, list, type) => {
  let after = [];
  switch (type) {
    case "tags":

    case "brands":
      for (let index = 0; index < arrayValue.length; index++) {
        let obj = {};

        let res = list.find((value) => arrayValue[index] == value.value);
        if (res) {
          obj.label = res.label;
          obj.value = arrayValue[index];
          after.push(obj);
        }
      }
      break;
    case "colors":
      for (let index = 0; index < arrayValue.length; index++) {
        let obj = {};
        let res = list.find((value) => arrayValue[index] == value.value);
        if (res) {
          obj.label = res.label;
          obj.value = arrayValue[index];
          obj.color = res.color;
          after.push(obj);
        }
      }
      break;
    default:
      break;
  }

  // for (let index = 0; index < arrayValue.length; index++) {
  //   let obj = {};
  //   obj.label = list.map((value) => {
  //     if (arrayValue[index] == value.id) {
  //       return value.txt;
  //     }
  //   })[0];
  //   obj.value = arrayValue[index];
  //   after.push(obj);
  // }
  return after;
};

const articleDataToQuery = (
  data,
  reverse,
  otherInformation = { tagList: [] }
) => {
  // article to data query

  // /specific-articles/keyword=keyOnetest&tags=test1,test2,test3&datefrom=11111111&dateto=2222222222222
  if (!reverse) {
    let { title, tags, dateRange } = data;
    let tagString = "";
    let finalQuery = "";
    for (let index = 0; index < tags.length; index++) {
      tagString = tagString + tags[index].value + "_";
    }
    if (tagString != "") {
      tagString = tagString.slice(0, -1);
    }
    // to do : title is going to be replace by id

    let dateFrom =
      dateRange[0] == undefined
        ? ""
        : typeof dateRange[0] == "number"
        ? dateRange[0] / 1000
        : dateRange[0].unix;

    let dateTo =
      dateRange[1] == undefined
        ? ""
        : typeof dateRange[1] == "number"
        ? dateRange[1] / 1000
        : dateRange[1].unix;

    finalQuery = `specific-articles/keyword=${title}&tags=${tagString}&datefrom=${dateFrom}&dateto=${dateTo}&`;
    return finalQuery;
  } else {
    // data query to article
    var regex = /(?<=\=)(.*?)(?=\&)/g;
    let dataArray = data.match(regex);
    let dataObject = {
      title: dataArray[0],
      tags:
        otherInformation.tagList.length > 0
          ? stringValueToReactSelect2(
              dataArray[1].split("_"),
              otherInformation.tagList,
              "tags"
            )
          : dataArray[1].split("_"),
      dateRange: [+dataArray[2] * 1000, +dataArray[3] * 1000],
    };
    return dataObject;
  }
};
const productDataToQuery = (
  data,
  reverse,
  otherInformation = { brandList: [], tagList: [], colorList: [] }
) => {
  // product to data query

  // /specific-product/keyword:keyOnetest&tags=test1,test2,test3&brands=3,4,5&priceform=10000&priceto=20000&rankform=10000&rankto=20000&color:3,22,1&sorttype=lowestprice,newest

  if (!reverse) {
    let { title, tags, brands, priceRange, rankRange, color, sortTypes } = data;
    let finalQuery = "";
    let tagString = "";
    let brandsString = "";
    let colorString = "";
    let { inventory, discounted, cheapest, mostExpensive, newest } = sortTypes;
    for (let index = 0; index < tags.length; index++) {
      tagString = tagString + tags[index].value + "_";
    }
    if (tagString != "") {
      tagString = tagString.slice(0, -1);
    }
    for (let index = 0; index < brands.length; index++) {
      brandsString = brandsString + brands[index].value + "_";
    }
    if (brandsString != "") {
      brandsString = brandsString.slice(0, -1);
    }

    for (let index = 0; index < color.length; index++) {
      colorString = colorString + color[index].value + "_";
    }
    if (colorString != "") {
      colorString = colorString.slice(0, -1);
    }

    // to do : title is going to be replace by id

    finalQuery = `specific-products/keyword=${title}&tags=${tagString}&brands=${brandsString}&priceform=${
      priceRange[0]
    }&priceto=${priceRange[1] == 0 ? "" : priceRange[1]}&rankform=${
      rankRange[0]
    }&rankto=${
      rankRange[1] == 0 ? "" : rankRange[1]
    }&color=${colorString}&sorttype=${inventory == true ? "inventory" : ""}_${
      discounted == true ? "discounted" : ""
    }_${cheapest == true ? "cheapest" : ""}_${
      mostExpensive == true ? "mostExpensive" : ""
    }_${newest == true ? "newest" : ""}&`;
    return finalQuery;
  } else {
    // data query to product
    var regex = /(?<=\=)(.*?)(?=\&)/g;
    let dataArray = data.match(regex);
    let temp = dataArray[8].split("_");
    let dataObject = {
      title: dataArray[0],

      tags:
        otherInformation.tagList.length > 0
          ? stringValueToReactSelect2(
              dataArray[1].split("_"),
              otherInformation.tagList,
              "tags"
            )
          : dataArray[1].split("_"),

      brands:
        otherInformation.brandList.length > 0
          ? stringValueToReactSelect2(
              dataArray[2].split("_"),
              otherInformation.brandList,
              "brands"
            )
          : dataArray[2].split("_"),

      priceRange: [dataArray[3], dataArray[4]],

      rankRange: [dataArray[5], dataArray[6]],

      color:
        otherInformation.colorList.length > 0
          ? stringValueToReactSelect2(
              dataArray[7].split("_"),
              otherInformation.colorList,
              "colors"
            )
          : dataArray[7].split("_"),

      sortTypes: {
        inventory: temp[0] == "" ? false : true,
        discounted: temp[1] == "" ? false : true,
        cheapest: temp[2] == "" ? false : true,
        mostExpensive: temp[3] == "" ? false : true,
        newest: temp[4] == "" ? false : true,
      },
    };
    return dataObject;
  }
};
const tokenGenerator = () => {
  let fd = new FormData();
  fd.append("uid", 4);
  fetch("https://ghodsfly.ir/data/api/generate_token_test", {
    method: "POST",
    body: fd,
  }).then((res) => {
    res.json().then((res) => {});
  });
};

export {
  unixToPersianDate,
  maskNumber,
  translateToReactSelect,
  phoneNumberValidation,
  objToFormData,
  articleDataToQuery,
  productDataToQuery,
};
