import axios from "axios";
import { objToFormData } from "utils/helperFunctions";
import Cookies from "js-cookie";

export default function sendRequest(functionName, data) {
  let formData;
  if (data instanceof FormData) {
    formData = data;
  } else if (typeof data == "object") {
    formData = objToFormData(data);
  } else if (data == undefined) {
    formData = new FormData();
  } else {
    // formData = data;
  }
  // formData.append("user_token", "UpoKj*12*VLMagUSEGBRGP2sQ*14**14*");
  formData.append("token", Cookies.get("token"));
  // to do: add user token from cookie

  return axios.post(`https://ghodsfly.ir/data/api/${functionName}`, formData);
}

function sendRequest3(functionName, data) {
  let formData;
  if (data instanceof FormData) {
    formData = data;
  } else if (typeof data == "object") {
    formData = objToFormData(data);
  } else if (data == undefined) {
    formData = new FormData();
  } else {
    // formData = data;
  }
  // formData.append("user_token", "UpoKj*12*VLMagUSEGBRGP2sQ*14**14*");
  formData.append("token", Cookies.get("token"));
  // to do: add user token from cookie

  return axios.post(`https://ghodsfly.ir/data/api3/${functionName}`, formData);
}
export { sendRequest3 };
