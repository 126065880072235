import sendRequest from "hooks/useAxios";
import { useState, useEffect } from "react";
import LeadItem from "components/LeadList/LeadItem";
import "../components/LeadList/lead-list.scss";
export default function LeadList() {
  const [leadList, setLeadList] = useState([]);
  useEffect(() => {
    sendRequest("Find_Leads", {
      tbl_name: `left join,products,product_id,id`,
    }).then((result) => {
      setLeadList(result.data);
    });
  }, []);
  return (
    <div className="mx-3 my-4 lead-list">
      {leadList.map((value) => {
        return <LeadItem item={value} />;
      })}
    </div>
  );
}
