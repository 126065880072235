import { Routes, Route } from "react-router-dom";
import Blog from "pages/Blog";
import CustomerMessage from "pages/CustomerMessage";
import CustomerOrders from "pages/CustomerOrders";
import Store from "pages/Store";
import SpecificProducts from "pages/SpecificProducts";
import SpecificArticles from "pages/SpecificArticles";
import SingleProduct from "pages/SingleProduct";
import Home from "pages/Home";
import Profile from "components/profile/Profile";
import CustomerLayout from "components/layout/CustomerLayout";
import AdminDashboard from "pages/AdminDashboard";
import UserManagement from "pages/User_Management";
import Article from "pages/SingleArticle";
import AdminLayout from "components/layout/AdminLayout";
import NotFound from "pages/NotFound";
import NotAllowed from "pages/NotAllowed";
import { useContext } from "react";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import AllPages from "pages/AllPages";
import AdminFinancial from "pages/AdminFinancial";
import AdminOrders from "pages/AdminOrder";
import Hashtags from "pages/Hashtags";

import CampainManagement from "../components/admin-panel/campain-management/CampainManagement";
import ClueManagement from "../components/admin-panel/clue-management/ClueManagement";
import LeadList from "pages/LeadList";

export default function AllRoutes() {
  const auth = useContext(WithAuthentication);

  return (
    <Routes>
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="" element={<Hashtags />} />
      {/* <Route path="blog" element={<Blog />} /> */}
      {/* <Route path="store" element={<Store />} /> */}
      {/* <Route path="specific-products/:query" element={<SpecificProducts />} />
      <Route path="specific-products" element={<SpecificProducts />} /> */}

      {/* <Route path="specific-articles/:query" element={<SpecificArticles />} />
      <Route path="specific-articles" element={<SpecificArticles />} /> */}

      {/* <Route path="article" element={<Article />} />
      <Route path="product" element={<SingleProduct />}></Route> */}

      {/* <Route path="product/:productId" element={<SingleProduct />} />
      <Route path="article/:articleId" element={<Article />} /> */}
      <Route path="all-pages" element={<AllPages />} />

      {/* customer panel: */}
      {/* <Route
        path="customer-panel/profile"
        element={
          (auth.userInfo.userType == 1 || auth.userInfo.userType == 2) && (
            <CustomerLayout>
              <Profile />
            </CustomerLayout>
          )
        }
      /> */}
      {/* <Route
        path="customer-panel/messages"
        element={
          (auth.userInfo.userType == 1 || auth.userInfo.userType == 2) && (
            <CustomerLayout>
              <CustomerMessage />
            </CustomerLayout>
          )
        }
      /> */}
      {/* <Route
        path="customer-panel/orders"
        element={
          (auth.userInfo.userType == 1 || auth.userInfo.userType == 2) && (
            <CustomerLayout>
              <CustomerOrders />
            </CustomerLayout>
          )
        }
      /> */}

      {/* admin panel: */}
      {/* <Route
        path="admin-panel/dashboard"
        element={
          auth.userInfo.userType == 0 && (
            <AdminLayout>
              <AdminDashboard />
            </AdminLayout>
          )
        }
      /> */}
      {/* <Route
        path="admin-panel/financial"
        element={
          auth.userInfo.userType == 0 && (
            <AdminLayout>
              <AdminFinancial />
            </AdminLayout>
          )
        }
      ></Route> */}
      {/* <Route
        path="admin-panel/orders"
        element={
          (auth.userInfo.userType == 0 || auth.userInfo.userType == 4) && (
            <AdminLayout>
              <AdminOrders />
            </AdminLayout>
          )
        }
      ></Route> */}
      {/* <Route
        path="admin-panel/user-management"
        element={
          auth.userInfo.userType == 0 && (
            <AdminLayout>
              <UserManagement />
            </AdminLayout>
          )
        }
      ></Route> */}
      {/* سرنخ */}
      {/* <Route
        path="admin-panel/clue-management"
        element={
          auth.userInfo.userType == 0 && (
            <AdminLayout>
              <ClueManagement />
            </AdminLayout>
          )
        }
      ></Route> */}
      {/* کمپین */}
      {/* <Route
        path="admin-panel/campain-management"
        element={
          auth.userInfo.userType == 0 && (
            <AdminLayout>
              <CampainManagement />
            </AdminLayout>
          )
        }
      ></Route> */}
      <Route
        path="admin-panel/lead-list"
        element={
          auth.userInfo.userType == 0 && (
            <AdminLayout>
              <LeadList />
            </AdminLayout>
          )
        }
      ></Route>

      <Route path="*" element={<NotFound />} />
      {/* <Route path="admin-panel/user_management" element={<UserManagement />} /> */}
    </Routes>
  );
}
