// import Select from "react-select";
import Creatable from "react-select/creatable";

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { useEffect, useState } from "react";
import {
  AiOutlineClear,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { HiOutlineSave } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import { addCommas, removeCommas } from "@persian-tools/persian-tools";
import { translateToReactSelect } from "utils/helperFunctions";
import toast from "react-hot-toast";
import React from "react";
import chroma from "chroma-js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Select, { StylesConfig } from "react-select";
import colors from "react-multi-date-picker/plugins/colors";

import sendRequest from "hooks/useAxios";
import { isObjectContainsInArray } from "utils/helperFunctions";
import { FilePond, registerPlugin, create } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import "filepond/dist/filepond.min.css";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Modal from "react-bootstrap/Modal";
import "./AddProductModal.scss";
import { Form } from "react-bootstrap";

// converting a color code and name to react select/ show with colors
const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles: StylesConfig = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isSelected ? chroma.contrast(color, "white") : data.color,
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? data.color : color.alpha(0.3).css(),
      },
    };
  },
  input: (styles) => ({ ...styles, border: "none" }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const translateColorToReactSelect = (arrayValue, reverse) => {
  let after = [];
  if (reverse) {
    // react select to api
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.color = arrayValue[index].label;
      obj.id = arrayValue[index].value;
      obj.hex = arrayValue[index].color;
      obj.inventory = arrayValue[index].inventory;
      after.push(obj);
    }
  } else {
    // api to react select
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.label = arrayValue[index].title;
      obj.value = arrayValue[index].id;
      obj.color = arrayValue[index].hex;
      after.push(obj);
    }
  }

  return after;
};

//react select with option that you can create new brand
// { value: "create", label: "ایجاد برند جدید", id:'0' }
const translateBrandToReactSelect = (arrayValue, reverse) => {
  let after = [];
  if (reverse) {
    // react select to api
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.name = arrayValue[index].label;
      obj.id = arrayValue[index].value;
      after.push(obj);
    }
  } else {
    // api to react select
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.label = arrayValue[index].name;
      obj.value = arrayValue[index].id;

      after.push(obj);
    }
  }

  return after;
};

const AddProductModal = ({ setUpdater, tags, show, setShow, navFirst }) => {
  const [displayStatus, setDisplayStatus] = useState("d-none");
  const [key, setKey] = useState("home");
  const saveData = (x) => {
    // setAddProduct((prev) => {
    //   return {
    //     ...prev,
    //     main_discount_endtime: `${addProduct.main_discount_endtime.unix}`,
    //     partner_discount_endtime: `${addProduct.partner_discount_endtime.unix}`,
    //     colors: JSON.stringify(addProduct.colors),
    //   };
    // });

    //

    let fd = new FormData();
    fd.append("title", addProduct.title);
    fd.append("bodytxt", addProduct.bodytxt);
    fd.append("description", addProduct.description);
    fd.append("main_price", addProduct.main_price);
    fd.append("main_discount", addProduct.main_discount);

    fd.append(
      "main_discount_endtime",
      addProduct.main_discount_endtime
        ? addProduct.main_discount_endtime.unix
        : 0
    );
    fd.append("partners_price", addProduct.partners_price);
    fd.append("partners_discount", addProduct.partners_discount);
    fd.append(
      "partner_discount_endtime",
      addProduct.partner_discount_endtime
        ? addProduct.partner_discount_endtime.unix
        : 0
    );
    fd.append(
      "inventory_count",
      inventorySwitch ? 1 : 0
      // addProduct.inventory_count ? addProduct.inventory_count : 0
    );
    fd.append("enable_upload_file", addProduct.enable_upload_file);
    // fd.append("Hashtags",addProduct.hashtags);
    fd.append("addHashtag", x.join(","));
    // fd.append(
    //   "partner_discount_endtime",
    //   addProduct.partner_discount_endtime &&
    //     addProduct.partner_discount_endtime.unix
    // );
    fd.append(
      "colorInventory",
      addProduct.colors && JSON.stringify(addProduct.colors, true)
    );
    // fd.append("brandid", addProduct.brandid == "create" && "");
    fd.append("brandid", 0);
    fd.append("file", addProduct.file);
    for (let i = 0; i < addProduct.files.length; i++) {
      fd.append("files[]", addProduct.files[i]);
    }
    sendRequest(
      "addproduct",
      fd

      // file: addProduct.cover_img,
    ).then((data) => {
      if (data.status == 200) {
        toast.success("محصول با موفقیت ایجاد شد.");
        setUpdater((prev) => !prev);
        setDisplayStatus("d-none");

        setAddProduct({
          title: "",
          description: "",
          main_price: "",
          main_discount: "",
          main_discount_endtime: "",
          partners_price: "",
          partners_discount: "",
          partner_discount_endtime: "",
          colors: [],
          tags: [],
          enable_upload_file: "0",
          brandid: "",
          bodytxt: "",
          file: "",
          files: "",
          inventory_count: "0",
        });
        setProductTag([]);
        setShow(false);
        // navigation to tak article
      } else {
        toast.error("مشکلی در افزودن محصول به وجود آمد");
      }
    });
  };
  const [update, setUpdate] = useState(false);

  const uploadFileFunc = (x) => {
    return x == "0" ? false : true;
  };
  // timestamo
  const timestampToDate = (time) => {
    const a = time * 1000;
    const date = new DateObject({ date: a, calendar: persian });
    return date;
  };

  // list of colors
  const [colors, setColors] = useState([]);
  const [colorOption, setColorOption] = useState([]);
  useEffect(() => {
    sendRequest("Find_color").then(({ data }) => {
      setColors(data);
    });
  }, []);
  useEffect(() => {
    setColorOption(translateColorToReactSelect(colors, false));
  }, [colors]);
  const clearInput = () => {
    setAddProduct({
      title: "",
      description: "",
      main_price: "",
      main_discount: "",
      main_discount_endtime: "",
      // cover_img: "",
      partners_price: "",
      partners_discount: "",
      partner_discount_endtime: "",
      colors: [],
      tags: [],
      enable_upload_file: "0",
      brandid: "",
      bodytxt: "",
      file: "",
      files: "",
      inventory_count: "0",
    });
  };
  useEffect(() => {
    clearInput();
    setProductTag([]);
    setValidateStatus({
      title: false,
      cover: false,
      price: false,
      mainDiscount: false,
      partnerDiscount: false,
      inventory: false,
    });
  }, [show]);
  const [inventory, setInventory] = useState(false);
  const [inventorySwitch, setInventorySwitch] = useState(false);

  // product state
  const [addProduct, setAddProduct] = useState({
    title: "",
    description: "",
    main_price: "",
    main_discount: "",
    main_discount_endtime: "",
    // cover_img: "",
    partners_price: "",
    partners_discount: "",
    partner_discount_endtime: "",
    colors: [],
    tags: [],
    enable_upload_file: "0",
    brandid: "",
    bodytxt: "",
    file: "",
    files: "",
    inventory_count: "0",
  });

  // list of tags

  // const [tags, setTags] = useState([]);
  // useEffect(() => {
  //   sendRequest("Find_hashtags").then(({ data }) => {
  //     setTags(data);
  //   });
  // }, []);
  const tagsOptions = translateToReactSelect(tags, false);

  const [productTag, setProductTag] = useState([]);
  // const insertHashtags = tagsArray => {
  //   return tagsArray.map(arrayItem => {
  //     if (arrayItem.__isNew__ == true) {
  //       sendRequest("Insert_hashtags", { txt: arrayItem.label }).then(
  //         ({ data }) => {
  //           addProduct.tags.push(data.result.id);
  //         }
  //       );
  //     } else {
  //       addProduct.tags.push(+arrayItem.value);
  //     }
  //   });
  // };
  const insertHashtags = async (tagsArray) => {
    return await Promise.all(
      tagsArray.map(async (arrayItem) => {
        if (arrayItem.__isNew__ == true) {
          return await sendRequest("Insert_hashtags", {
            txt: arrayItem.label,
          }).then(({ data }) => {
            return data.result.id;
          });
        } else {
          return +arrayItem.value;
        }
      })
    );
  };
  // list of brands
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    sendRequest("Find_brand").then(({ data }) => {
      setBrands((prev) => {
        return [{ name: "ثبت برند جدید", id: "create" }, ...data];
      });
    });
  }, [update]);

  const brandsOptions = translateBrandToReactSelect(brands, false);

  // new brand
  const [newBrand, setNewBrand] = useState({ name: "", iconpic: "" });

  const insertBrand = () => {
    sendRequest("addbrand", newBrand).then(({ data }) => {
      if (data.status == 1) {
        toast.success("برند با موفقیت اضافه شد.");

        setUpdate((prev) => !prev);
      } else {
        toast.error("مشکلی در افزودن برند پیش آمده است.");
      }
    });
  };

  // validation
  const [validateStatus, setValidateStatus] = useState({
    title: false,
    cover: false,
    price: false,
    description: false,
    // partnerPrice: false,
    mainDiscount: false,
    partnerDiscount: false,
    inventory: false,
  });
  const testInventory = () => {
    let add = 0;
    for (let i = 0; i < addProduct.colors.length; i++) {
      add += +addProduct.colors[i].inventory;
    }

    if (addProduct.inventory_count >= add) {
      setValidateStatus((prev) => {
        return { ...prev, inventory: false };
      });
      return true;
    } else {
      setValidateStatus((prev) => {
        return { ...prev, inventory: true };
      });
      return false;
    }
  };

  const validation = () => {
    let valid = true;
    if (addProduct.description != "") {
      setValidateStatus((prev) => {
        return { ...prev, description: false };
      });
    } else if (addProduct.description == "") {
      setValidateStatus((prev) => {
        return { ...prev, description: true };
      });
      valid = false;
    }
    if (addProduct.file != "") {
      setValidateStatus((prev) => {
        return { ...prev, cover: false };
      });
    } else if (addProduct.file == "") {
      setValidateStatus((prev) => {
        return { ...prev, cover: true };
      });
      valid = false;
    }
    // if (addProduct.main_price != "") {
    //   setValidateStatus(prev => {
    //     return { ...prev, price: false };
    //   });
    // } else if (addProduct.main_price == "") {
    //   setValidateStatus(prev => {
    //     return { ...prev, price: true };
    //   });
    //   valid = false;
    // }
    // if (addProduct.inventory_count == 0 || addProduct.inventory_count == "") {
    //   setValidateStatus(prev => {
    //     return { ...prev, inventory: true };
    //   });
    //   valid = false;
    // } else {
    //   setValidateStatus(prev => {
    //     return { ...prev, inventory: false };
    //   });
    // }
    // if (addProduct.partners_price == "") {
    //   setValidateStatus(prev => {
    //     return { ...prev, partnerPrice: true };
    //   });
    //   valid = false;
    // } else {
    //   setValidateStatus(prev => {
    //     return { ...prev, partnerPrice: false };
    //   });
    // }
    // if (!testInventory()) {
    //   valid = false;
    // }
    return valid;
  };

  const [validateInventory, setValidateInventory] = useState("d-none");

  const [inventoryTrue, setInventoryTrue] = useState({
    color: "",
    inventory: "",
  });
  let inventoryDisable = inventory.color == "" ? true : false;
  console.log(inventorySwitch);
  return (
    <>
      {/* <div className="modal" id="addProductModal" tabIndex="-1"> */}
      {/* <div className="modal-dialog modal-lg modal-fullscreen-lg-down"> */}
      {/* <div className="modal-content"> */}
      {/* <div className="modal-header"> */}
      <div className="addproduct-style">
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          size={"lg"}
          className="modal-style"
        >
          <Modal.Dialog size={"xl"}>
            <Modal.Header>
              <h5>تعریف محصول جدید </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setAddProduct({
                    title: "",
                    description: "",
                    main_price: "",
                    main_discount: "",
                    main_discount_endtime: "",
                    // cover_img: "",
                    partners_price: "",
                    partners_discount: "",
                    partner_discount_endtime: "",
                    colors: [],
                    tags: [],
                    enable_upload_file: "0",
                    brandid: "",
                    bodytxt: "",
                    file: "",
                    files: "",
                    inventory_count: "0",
                  });
                  setShow(false);
                  setProductTag([]);
                }}
              ></button>
            </Modal.Header>
            {/* </div> */}
            {/* <div className="modal-body"> */}
            <Modal.Body>
              <form name="addProduct">
                <div>
                  {/* <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        class="nav-link active"
                        id="nav-info-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-info"
                        type="button"
                        role="tab"
                        aria-controls="nav-info"
                        aria-selected="true"
                      >
                        اطلاعات اولیه
                      </button>
                      <button
                        class="nav-link"
                        id="nav-sell-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-sell"
                        type="button"
                        role="tab"
                        aria-controls="nav-sell"
                        aria-selected="false"
                      >
                        شرایط فروش
                      </button>
                      <button
                        class="nav-link"
                        id="nav-more-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-more"
                        type="button"
                        role="tab"
                        aria-controls="nav-more"
                        aria-selected="false"
                      >
                        اطلاعات تکمیلی
                      </button>
                    </div>
                  </nav> */}
                  <Tabs
                    id="uncontrolled-tab-example"
                    // activeKey={key}
                    // onSelect={k => setKey(k)}
                    className="mb-3"
                    defaultActiveKey={navFirst}
                  >
                    <Tab eventKey="home" title="اطلاعات اولیه">
                      <div className="d-flex first-container">
                        {/* عنوان */}
                        {/* <div className="my-2 mx-1 align-items-baseline title1">
                          <label for="title" className="">
                            عنوان:
                          </label>
                          <input
                            id="title"
                            className="round form-control"
                            type="text"
                            // defaultValue={product.title}
                            value={addProduct.title}
                            required
                            onChange={(e) => {
                              setAddProduct((prev) => {
                                return { ...prev, title: e.target.value };
                              });
                            }}
                            onKeyUp={() => {
                              if (addProduct.title == "") {
                                setValidateStatus((prev) => {
                                  return { ...prev, title: true };
                                });
                              } else {
                                setValidateStatus((prev) => {
                                  return { ...prev, title: false };
                                });
                              }
                            }}
                          ></input>
                          <div
                            className={`ms-1 ${
                              validateStatus.title == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            فیلد عنوان نباید خالی باشد.
                          </div>
                        </div> */}
                        {/* برند */}
                        {/* <div className="my-2 mx-1 brand">
                          <label for="brand">برند:</label>
                          <Select
                            id="brand"
                            options={brandsOptions}
                            // value={brandsOptions.filter(
                            //   i => i.value == addProduct.brandid
                            // )}
                            value={addProduct.brandid}
                            onChange={(e) => {
                              setAddProduct((prev) => {
                                return { ...prev, brandid: e };
                              });

                              if (e.value == "create") {
                                setDisplayStatus("d-block");
                              } else {
                                setDisplayStatus("d-none");
                              }
                            }}
                          />
                        </div> */}
                      </div>
                      {/* ایجاد برند جدید */}
                      {/* <div
                        className={`d-flex ${displayStatus} rounded`}
                        style={{ border: "1px dashed lightgray" }}
                      >
                        <div className="w-50 p-1">
                          <label>نام برند:</label>
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) => {
                              setNewBrand((prev) => {
                                return { ...prev, name: e.target.value };
                              });
                            }}
                          ></input>
                        </div>
                        <div className="w-50 p-1">
                          <div className="d-flex justify-content-between">
                            <label>ایکن برند:</label>
                            <HiOutlineSave
                              className="me-2 text-success  cursor-pointer"
                              data-tip="ثبت برند جدید"
                              onClick={insertBrand}
                            />
                            <ReactTooltip />
                          </div>

                          <input
                            className="form-control"
                            type="file"
                            onChange={(e) => {
                              setNewBrand((prev) => {
                                return { ...prev, file: e.target.files[0] };
                              });
                            }}
                          ></input>
                        </div>
                      </div> */}

                      {/* توضیحات */}
                      <div className="my-2 align-items-baseline">
                        <label for="txt" className="">
                          توضیحات:{" "}
                        </label>
                        <textarea
                          id="txt"
                          className=" form-control"
                          value={addProduct.description}
                          onChange={(e) => {
                            setAddProduct((prev) => {
                              return { ...prev, description: e.target.value };
                            });
                          }}
                        ></textarea>
                        <div
                          className={`ms-1 ${
                            validateStatus.description == true
                              ? "d-block"
                              : "d-none"
                          } validation-txt`}
                        >
                          فیلد توضیحات نباید خالی باشد.
                        </div>
                      </div>
                      {/* تگهای محصول */}
                      <div className="my-2">
                        <label for="tags">برچسبهای محصول:</label>
                        <Creatable
                          id="tags"
                          options={tagsOptions}
                          value={productTag}
                          isMulti={true}
                          className="mx-1"
                          onChange={(e) => {
                            setProductTag(e);
                          }}
                        />
                      </div>

                      <div className="d-flex second-container">
                        {/* کاور */}
                        <div className="my-2 mx-1 align-items-baseline other-media">
                          <label for="coverPic" className="">
                            کاور محصول:
                          </label>
                          <input
                            id="coverPic"
                            className=" form-control "
                            type="file"
                            onChange={(e) => {
                              setAddProduct((prev) => {
                                return { ...prev, file: e.target.files[0] };
                              });
                            }}
                          ></input>
                          <div
                            className={`ms-1 ${
                              validateStatus.cover == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            فیلد کاور نباید خالی باشد.
                          </div>
                        </div>
                        {/* فایلهای جانبی */}
                        <div className="my-2 mx-1 align-items-baseline cover ">
                          <label for="otherMedia">فایلهای جانبی:</label>
                          <input
                            className="form-control"
                            id="otherMedia"
                            type="file"
                            multiple
                            onChange={(e) => {
                              setAddProduct((prev) => {
                                return { ...prev, files: e.target.files };
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="mt-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="موجود است."
                          checked={inventorySwitch}
                          onChange={(e) => {
                            setInventorySwitch((prev) => !prev);
                          }}
                        />
                      </div>
                      {/* اپلود فایل */}
                      {/* <div className="form-check my-2">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          id="flexCheckDefault"
                          checked={uploadFileFunc(
                            addProduct.enable_upload_file
                          )}
                          onChange={() => {
                            setAddProduct(prev => {
                              return {
                                ...prev,
                                enable_upload_file:
                                  addProduct.enable_upload_file == "1"
                                    ? "0"
                                    : "1"
                              };
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          امکان اپلود فایل توسط مشتری
                        </label>
                      </div> */}
                    </Tab>
                    {/* <Tab eventKey="profile" title="شرایط فروش">
                     
                      <div className="d-flex justify-content-between align-items-baseline price-date">
                        <div className="m-1 main-price-item">
                          <label for="mainPrice" className="fs-14">
                            قیمت (تومان)
                          </label>

                          <input
                            id="mainPrice"
                            className=" form-control "
                            value={addCommas(addProduct.main_price)}
                            onChange={(e) => {
                              setAddProduct((prev) => {
                                return {
                                  ...prev,
                                  main_price: removeCommas(e.target.value),
                                };
                              });
                            }}
                            onKeyUp={() => {
                              if (addProduct.main_price == "") {
                                setValidateStatus((prev) => {
                                  return { ...prev, price: true };
                                });
                              } else {
                                setValidateStatus((prev) => {
                                  return { ...prev, price: false };
                                });
                              }
                            }}
                          ></input>
                          <div
                            className={`ms-1 ${
                              validateStatus.price == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            فیلد قیمت نباید خالی باشد.
                          </div>
                        </div>
                        <div className="m-1 main-price-item">
                          <div>
                            <label for="mainDiscount" className="fs-14">
                              تخفیف (تومان)
                            </label>
                            <input
                              id="mainDiscount"
                              className=" form-control "
                              value={addCommas(addProduct.main_discount)}
                              onChange={(e) => {
                                if (
                                  removeCommas(e.target.value) >=
                                  addProduct.main_price
                                ) {
                                  setValidateStatus((prev) => {
                                    return { ...prev, mainDiscount: true };
                                  });
                                } else {
                                  setValidateStatus((prev) => {
                                    return { ...prev, mainDiscount: false };
                                  });
                                  setAddProduct((prev) => {
                                    return {
                                      ...prev,
                                      main_discount: removeCommas(
                                        e.target.value
                                      ),
                                    };
                                  });
                                }
                              }}
                            ></input>
                          </div>
                          <div
                            className={`ms-1 ${
                              validateStatus.mainDiscount == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            مبلغ تخفیف از مبلغ اصلی نباید بیشتر باشد.
                          </div>
                        </div>

                        <div className="m-1 main-price-item">
                          <div className="d-flex justify-content-between">
                            <label for="discountDate" className="fs-14">
                              تا تاریخ
                            </label>
                            <AiOutlineClear
                              data-tip="تخفیف بدون محدودیت زمانی"
                              className="align-self-center cursor-pointer text-danger"
                              onClick={() => {
                                setAddProduct((prev) => {
                                  return {
                                    ...prev,
                                    main_discount_endtime: null,
                                  };
                                });
                              }}
                            />
                            <ReactTooltip />
                          </div>

                          <div className="w-100">
                            <DatePicker
                              className="w-100"
                              name="mainDate"
                              id="discountDate"
                              plugins={[<TimePicker position="bottom" />]}
                              calendar={persian}
                              locale={persian_fa}
                              format=" HH:mm:ss YYYY/MM/DD"
                              value={addProduct.main_discount_endtime}
                              onChange={(value) => {
                                setAddProduct((prev) => {
                                  return {
                                    ...prev,
                                    main_discount_endtime: value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-baseline price-date">
                        <div className=" mx-1 my-2 main-price-item">
                          <label for="partnerPrice" className="fs-14">
                            قیمت همکار(تومان)
                          </label>
                          <input
                            id="partnerPrice"
                            className=" form-control"
                            value={addCommas(addProduct.partners_price)}
                            onChange={(e) => {
                              setAddProduct((prev) => {
                                return {
                                  ...prev,
                                  partners_price: removeCommas(e.target.value),
                                };
                              });
                            }}
                          ></input>
                        
                        </div>
                        <div className=" mx-1 my-2 main-price-item">
                          <div>
                            <label for="partnerDiscount" className="fs-14">
                              تخفیف همکار(تومان)
                            </label>
                            <input
                              id="partnerDiscount"
                              className=" form-control"
                              value={addCommas(addProduct.partners_discount)}
                              onChange={(e) => {
                                if (
                                  removeCommas(e.target.value) >=
                                  addProduct.partners_price
                                ) {
                                  setValidateStatus((prev) => {
                                    return { ...prev, partnerDiscount: true };
                                  });
                                } else {
                                  setValidateStatus((prev) => {
                                    return { ...prev, partnerDiscount: false };
                                  });
                                  setAddProduct((prev) => {
                                    return {
                                      ...prev,
                                      partners_discount: removeCommas(
                                        e.target.value
                                      ),
                                    };
                                  });
                                }
                              }}
                            ></input>
                          </div>
                          <div
                            className={`ms-1 ${
                              validateStatus.partnerDiscount == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            قیمت تخفیف نباید بیشتر از مبلغ کل باشد..
                          </div>
                        </div>

                        <div className=" mx-1 my-2 main-price-item">
                          <div className="d-flex justify-content-between">
                            <label for="partnerDiscountDate" className="fs-14">
                              تا تاریخ
                            </label>
                            <AiOutlineClear
                              data-tip="تخفیف بدون محدودیت زمانی"
                              className="align-self-center cursor-pointer text-danger"
                              onClick={() => {
                                setAddProduct((prev) => {
                                  return {
                                    ...prev,
                                    partner_discount_endtime: null,
                                  };
                                });
                              }}
                            />
                            <ReactTooltip />
                          </div>
                          <div className="w-100">
                            <DatePicker
                              className="w-100"
                              id="discountDatePartner"
                              plugins={[<TimePicker position="bottom" />]}
                              calendar={persian}
                              locale={persian_fa}
                              format=" HH:mm:ss YYYY/MM/DD"
                              value={addProduct.partner_discount_endtime}
                              onChange={(value) => {
                                setAddProduct((prev) => {
                                  return {
                                    ...prev,
                                    partner_discount_endtime: value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="m-1 main-price-item">
                            <label for="inventory" className="">
                              موجودی کل:
                            </label>

                            <input
                              id="inventory"
                              className=" form-control "
                              value={addProduct.inventory_count}
                              onChange={(e) => {
                                setAddProduct((prev) => {
                                  return {
                                    ...prev,
                                    inventory_count: e.target.value,
                                  };
                                });
                              }}
                              onKeyUp={() => {
                                if (addProduct.inventory_count == "") {
                                  setValidateStatus((prev) => {
                                    return { ...prev, inventory: true };
                                  });
                                } else {
                                  setValidateStatus((prev) => {
                                    return { ...prev, inventory: false };
                                  });
                                }
                              }}
                            ></input>
                            <div
                              className={`ms-1 ${
                                validateStatus.inventory == true
                                  ? "d-block"
                                  : "d-none"
                              } validation-txt`}
                            >
                              فیلد موجودی نباید خالی باشد
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    </Tab> */}
                    {/* <Tab eventKey="contact" title="اطلاعات تکمیلی">
                      <div className="my-3" dir="">
                        <SunEditor
                          setOptions={{
                            height: 250,
                            buttonList: [
                              [],
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                                "bold",
                                "underline",
                                "strike",
                                "fontColor",
                                "textStyle",
                                "removeFormat",
                                "align",
                                "horizontalRule",
                                "list",
                                "lineHeight",
                                "link",
                                "image",
                                "video",
                                "audio",
                                "imageGallery",
                                "fullScreen",
                                "preview",
                              ],
                            ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                          }}
                          setContents={addProduct.bodytxt}
                          onChange={(content) => {
                            setAddProduct((prev) => {
                              return { ...prev, bodytxt: content };
                            });
                          }}
                        />
                      </div>
                    </Tab> */}
                  </Tabs>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-success px-4"
                    onClick={async () => {
                      console.log("inventoree stat", validateStatus.inventory);
                      if (validation()) {
                        let x = await insertHashtags(productTag);

                        saveData(x);
                      }
                    }}
                  >
                    ثبت
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal.Dialog>
        </Modal>
      </div>
      {/* //   </div> */}
      {/* // </div> */}
      {/* </div> */}
    </>
  );
};

export default AddProductModal;
