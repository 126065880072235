import { BrowserRouter } from "react-router-dom";
import AllRoutes from "routes/Routes";
import Header from "components/main/Header";
import Footer from "components/main/Footer";
import Mobile from "components/main/Mobile";
import { ScrollToTop } from "react-router-scroll-to-top";
import WithAuthentication from "Authentication/WithAuthentication.tsx";
import { Toaster } from "react-hot-toast";
import ReactTooltip from "react-tooltip";
import CartModal from "common/order/CartModal";
import WithAuthenticationContext from "Authentication/AuthenticationContext.ts";
import { useContext, useState, useEffect } from "react";
import { CartItemsProvider } from "context/CardItemsProvider";
import withClearCache from "hoc/WithClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function MainApp() {
  const auth = useContext(WithAuthenticationContext);
  const [backButtonPressed, setBackButtonPressed] = useState(false);
  useEffect(() => {
    window.onpopstate = () => {
      setBackButtonPressed((prev) => !prev);
    };
  }, []);
  return (
    <div className="App">
      <WithAuthentication>
        {/* <CartItemsProvider> */}
          {" "}
          <ReactTooltip></ReactTooltip>
          <div>
            <Toaster />
          </div>
          <BrowserRouter>
            <ScrollToTop />
            <Header backButtonPressed={backButtonPressed}></Header>

            {auth.userInfo.userType == 1 && <CartModal></CartModal>}

            <AllRoutes></AllRoutes>
            <Footer>{/* <Mobile /> */}</Footer>
          </BrowserRouter>
        {/* </CartItemsProvider>{" "} */}
      </WithAuthentication>
    </div>
  );
}

function App() {
  return <ClearCacheComponent />;
}

export default App;
