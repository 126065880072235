import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import "swiper/css/navigation";

import { FiEdit } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import "./store.css";
import { useContext, useState, useEffect } from "react";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import sendRequest from "hooks/useAxios";
import toast from "react-hot-toast";
export default ({ isAdmin }) => {
  const [updater, setUpdater] = useState(false);
  const [productList, setProductList] = useState([]);
  const [saveInformation, setSaveInformation] = useState([
    { id: "", file: "", url: "" },
    { id: "", file: "", url: "" },
    { id: "", file: "", url: "" },
    { id: "", file: "", url: "" },
  ]);
  const [showInformation, setShowInformation] = useState([
    { id: "", url: "" },
    { id: "", url: "" },
    { id: "", url: "" },
    { id: "", url: "" },
  ]);

  useEffect(() => {
    sendRequest("Find_boxs_configs", {
      conditions: "pagecode/=/3@@boxcode/=/4",
    }).then(({ data }) => {
      let temp = data[0].custom_config.split(",");
      setSaveInformation([
        { id: temp[0], url: temp[1], file: "" },
        { id: temp[2], url: temp[3], file: "" },
        { id: temp[4], url: temp[5], file: "" },
        { id: temp[6], url: temp[7], file: "" },
      ]);
      setShowInformation([
        { id: temp[0], url: temp[1] },
        { id: temp[2], url: temp[3] },
        { id: temp[4], url: temp[5] },
        { id: temp[6], url: temp[7] },
      ]);
    });
  }, [updater]);
  useEffect(() => {
    sendRequest("getlistofproduct").then(({ data }) => {
      setProductList(data.productList);
    });
  }, []);

  const options = () =>
    productList != []
      ? productList.map((value) => {
          return <option value={value.id}>{value.title}</option>;
        })
      : [];

  const changeHandler = (e, index, type) => {
    if (type == 0) {
      setSaveInformation((prev) => {
        let temp = [...prev];
        temp[index].file = e.target.files[0];
        return temp;
      });
    } else if (type == 1) {
      setSaveInformation((prev) => {
        let temp = [...prev];
        temp[index].id = e.target.value;
        return temp;
      });
    }
  };
  const updateSection = () => {
    let fd = new FormData();
    fd.append(
      "ids",
      saveInformation
        .map((value) => {
          return value.id;
        })
        .join()
    );
    for (let i = 0; i < saveInformation.length; i++) {
      if (saveInformation[i].file == "") {
        fd.append(`url_${i}`, saveInformation[i].url);
      } else {
        fd.append(`file_${i}`, saveInformation[i].file);
      }
    }
    sendRequest("save_shop_slider", fd).then(({ data }) => {
      if (data.status == 1) {
        toast.success("با موفقیت ویرایش شد.");
        document.querySelector("#editModal .btn-close").click();

        setUpdater((prev) => {
          return !prev;
        });
      } else {
        toast.error("مشکلی در ویرایش پیش آمده است.");
      }
    });
  };
  const EditCoverModal = (
    <div
      className="modal fade"
      id="editModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div>
              <h5>اسلاید اول: </h5>
              <div className="d-flex ">
                <label for="file-1" className="mx-3">
                  فایل
                </label>
                <input
                  type="file"
                  className="form-control ms-2"
                  onChange={(e) => {
                    changeHandler(e, 0, 0);
                  }}
                />
              </div>
              <div className="d-flex mt-2">
                <label for="select-1" className="mx-3">
                  محصول
                </label>
                <select
                  className=""
                  className="form-control form-select"
                  value={saveInformation[0].id}
                  onChange={(e) => {
                    changeHandler(e, 0, 1);
                  }}
                >
                  {options}
                </select>
              </div>
            </div>

            <div className="mt-3">
              <h5>اسلاید دوم: </h5>
              <div className="d-flex ">
                <label for="file-1" className="mx-3">
                  فایل
                </label>
                <input
                  type="file"
                  className="form-control ms-2"
                  onChange={(e) => {
                    changeHandler(e, 1, 0);
                  }}
                />
              </div>
              <div className="d-flex mt-2">
                <label for="select-1" className="mx-3">
                  محصول
                </label>
                <select
                  className=""
                  value={saveInformation[1].id}
                  className="form-control form-select"
                  onChange={(e) => {
                    changeHandler(e, 1, 1);
                  }}
                >
                  {options}
                </select>
              </div>
            </div>
            <div className="mt-3">
              <h5>اسلاید سوم: </h5>
              <div className="d-flex ">
                <label for="file-1" className="mx-3">
                  فایل
                </label>
                <input
                  type="file"
                  className="form-control ms-2"
                  onChange={(e) => {
                    changeHandler(e, 2, 0);
                  }}
                />
              </div>
              <div className="d-flex mt-2">
                <label for="select-1" className="mx-3">
                  محصول
                </label>
                <select
                  className=""
                  value={saveInformation[2].id}
                  className="form-control form-select"
                  onChange={(e) => {
                    changeHandler(e, 2, 1);
                  }}
                >
                  {options}
                </select>
              </div>
            </div>
            <div className="mt-3">
              <h5>اسلاید چهارم: </h5>
              <div className="d-flex ">
                <label for="file-1" className="mx-3">
                  فایل
                </label>
                <input
                  type="file"
                  className="form-control ms-2"
                  onChange={(e) => {
                    changeHandler(e, 3, 0);
                  }}
                />
              </div>
              <div className="d-flex mt-2">
                <label for="select-1" className="mx-3">
                  محصول
                </label>
                <select
                  className=""
                  value={saveInformation[3].id}
                  className="form-control form-select"
                  onChange={(e) => {
                    changeHandler(e, 3, 1);
                  }}
                >
                  {options}
                </select>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              بستن
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={updateSection}
            >
              ذخیره
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="mt-3">
      {isAdmin && (
        <div
          className=" p-1 text-light rounded m-2"
          data-bs-toggle="modal"
          data-bs-target="#editModal"
          style={{
            backgroundColor: "navy",
            display: "inline",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          <FiEdit className=" rounded  mx-1"></FiEdit>
          ویرایش
        </div>
      )}
      <Swiper
        spaceBetween={10}
        slidesPerView={"auto"}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        loop={true}
      >
        {showInformation.map((value, index) => {
          return (
            <>
              <SwiperSlide className="ad-slider my-3 ">
                <NavLink to={`/product/${value.id}`}>
                  <img
                    src={"https://ghodsfly.ir/data/" + value.url}
                    className="w-100 h-100 "
                    style={{ borderRadius: "8px" }}
                  ></img>
                </NavLink>
              </SwiperSlide>
            </>
          );
        })}
      </Swiper>
      {ReactDOM.createPortal(
        EditCoverModal,
        document.getElementById("editCoverModal")
      )}
    </div>
  );
};
