import { Modal } from "react-bootstrap";
import "./add-lead.scss";
import { useState } from "react";
import toast from "react-hot-toast";
import { sendRequest3 } from "hooks/useAxios";
export default function AddLead({ showModal, setShowModal, selectedProduct }) {
  const [submitData, setSubmitData] = useState({ name: "", mobile: "" });
  const [validate, setValidate] = useState({ status: false, txt: "" });
  function mobileChecker(mobile) {
    const mobileRgx =
      /^(\+98|0)( |-){0,1}9\d{2}( |-){0,1}\d{3}( |-){0,1}\d{4}$/;
    return mobileRgx.test(mobile);
  }
  const dataHandler = (object) => {
    setSubmitData((prev) => {
      return { ...prev, ...object };
    });
  };
  const validation = () => {
    let validate = true;
    if (submitData.name == "" || submitData.mobile == "") {
      setValidate((prev) => {
        return {
          ...prev,
          status: true,
          txt: "  نام و نام خانوادگی و شماره تماس باید وارد شود.",
        };
      });
      validate = false;
    } else if (!mobileChecker(submitData.mobile)) {
      setValidate((prev) => {
        return {
          ...prev,
          status: true,
          txt: "شماره وارد شده صحیح نیست.",
        };
      });
    } else
      setValidate((prev) => {
        return {
          ...prev,
          status: false,
          txt: "",
        };
      });
    return validate;
  };
  const addData = () => {
    sendRequest3("add_lead", {
      product_id: selectedProduct?.id,
      product_description: selectedProduct?.description,
      name: submitData?.name,
      mobile: submitData?.mobile,
    }).then((result) => {
      console.log("dkdf", result);
      if (result.data.status == 1) {
        toast.success(
          "با موفقیت ثبت شد. کارشناسان ما به زودی با شما تماس می گیرند.."
        );
        setShowModal(false);
      } else if (result.data.status == -1) {
        toast.error("قبلا برای این محصول اطلاعات خود را ثبت کرده اید.");
        setShowModal(false);
      } else toast.error("در ثبت مشکلی پیش آمده لطفا مجدد تلاش کنید.");
    });
  };
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        برای تماس سریع پرسنل ما با شما لطفا نام و موبایل خود را ثبت کنید. پرسنل
        ما به زودی با شما تماس می گیرند.
      </Modal.Header>
      <Modal.Body className="add-lead">
        <div className="mb-2 rows">
          <label className="label">نام و نام خانوادگی:</label>
          <input
            className="form-control input"
            value={submitData?.name}
            onChange={(e) => {
              dataHandler({ name: e.target.value });
            }}
          ></input>
        </div>
        <div className="mt-3 rows">
          <label className="label">شماره تماس:</label>
          <input
            className="form-control input"
            value={submitData?.mobile}
            onChange={(e) => {
              if (!isNaN(e.target.value))
                dataHandler({ mobile: e.target.value });
              else toast.error("لطفا فقط عدد وارد کنید.");
            }}
          ></input>
        </div>
        {validate?.status && (
          <div className="mt-2 text-end" style={{ fontSize: 12, color: "red" }}>
            {validate?.txt}
          </div>
        )}
        <div className="d-flex justify-content-end">
          {" "}
          <button
            className="btn bg-success text-light mt-4"
            onClick={() => {
              if (validation()) {
                addData();
              }
            }}
          >
            ثبت
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
