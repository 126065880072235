import LogInCode from "./LogInCode";
import LogInNumber from "./LogInNumber";
import React, { useState } from "react";

export default function Container({ changeUserInformation }) {
  const [modal, setmodal] = useState(0);
  const [loginkey, setloginkey] = useState("");
  const [number, setnumber] = useState("");

  let SetNumber = (e) => {
    setnumber(e.target.value);
  };

  function changemodal(modalNumber) {
    setmodal(modalNumber);
  }

  function LoginKey(loginKey) {
    setloginkey(loginKey);
  }

  function Back() {
    setmodal(0);
  }

  return (
    <>
      <div style={{ direction: "rtl" }}>
        {/* <!-- Modal --> */}
        <div className="modal fade hide" id="ModalNumber" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex flex-row justify-content-between">
                {modal == 0 ? (
                  ""
                ) : (
                  <button type="button" className="btn border" style={{ boxShadow: "none" }} onClick={Back}>
                    بازگشت
                  </button>
                )}
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="CloseModal"></button>
              </div>

              {modal == 0 ? <LogInNumber SetNumber={SetNumber} number={number} login={LoginKey} ChangeModal={changemodal} /> : <LogInCode ChangeModal={changemodal} loginkey={loginkey} SetNumber={SetNumber} changeUserInformation={changeUserInformation} number={number} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
