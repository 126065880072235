import { createContext } from "react";
interface UserInformation {
  token: string;
  fullName: string;
  userType: number;
  mobile: number;
  userName: string;
  accessLevel: any;
  id: any;
}
interface AuthenticationInterface {
  userInfo: UserInformation;
  isLogged: boolean;
  login: (token: string, fullName: string, userType: number, mobile: number, userName: string, accessLevel: any, id: any) => void;
  logout: () => void;
}

const AuthenticationContext = createContext<AuthenticationInterface>({
  userInfo: { token: "", fullName: "", userType: -1, mobile: 0, userName: "", accessLevel: [], id: null },
  isLogged: false,
  login: () => {},
  logout: () => {},
});
export default AuthenticationContext;
// account types:
// 1: customer
// 2: tour leader
// 3:حسابدار
// 4:کانتر فروش
// 5:مدیریت کل
// status = -10 suspend
