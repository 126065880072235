import melat from "assets/images/mellat.png";
import saderat from "assets/images/Saderat.png";
import { createPortal } from "react-dom";
import ShoppingListItem from "./ShopingListItem";
import { useState, useEffect } from "react";

import sendRequest2 from "hooks/useAxios2";
import sendRequest from "hooks/useAxios";
import Select from "react-select";

import CardItemsContext from "context/CardItemsProvider";
import { useContext } from "react";
import toast from "react-hot-toast";
import zarinpal from "assets/images/zarinpal.png";
import { useNavigate } from "react-router-dom";
export default function CartModal({ auth }) {
  const {
    shoppingData,
    updateCartItem,
    cartItemCounter,
    setCountUpdater,
    cartItemsCount,
  } = useContext(CardItemsContext);
  const phoneNumberValidation = (phone) => {
    return /^09\d{9}$/.test(phone);
  };
  const navigation = useNavigate();
  const postalCodeValidation = (postalCode) => {
    let myRejex = /\d{5}[\-]?\d{5}/;
    myRejex.test(postalCode);
  };
  // const {
  //   shoppingData,
  //   updateCartItem,
  //   cartItem,
  //   isLoading,
  //   setIsLoading,
  //   productUpdater,
  //   setProductUpdater
  // } = useContext(CartItemsContext);
  const [validateValues, setValidateValues] = useState({
    fName: true,
    lName: true,
    // province: true,
    city: true,
    postalCode: true,
    address: true,
    phone: true,
    BankPortal: true,
  });
  const validation = () => {
    let isValid = true;
    if (phoneNumberValidation(addModalCart.phone)) {
      setValidateValues((prev) => {
        return { ...prev, phone: true };
      });
    } else {
      isValid = false;
      setValidateValues((prev) => {
        return { ...prev, phone: false };
      });
    }

    if (addModalCart.fName != "") {
      setValidateValues((prev) => {
        return { ...prev, fName: true };
      });
    } else {
      isValid = false;
      setValidateValues((prev) => {
        return { ...prev, fName: false };
      });
    }
    if (addModalCart.lName != "") {
      setValidateValues((prev) => {
        return { ...prev, lName: true };
      });
    } else {
      isValid = false;
      setValidateValues((prev) => {
        return { ...prev, lName: false };
      });
    }
    // if (addModalCart.province.value != "") {
    //   setValidateValues(prev => {
    //     return { ...prev, province: true };
    //   });
    // } else {
    //   isValid = false;
    //   setValidateValues(prev => {
    //     return { ...prev, province: false };
    //   });
    // }
    if (addModalCart.city.value != "") {
      setValidateValues((prev) => {
        return { ...prev, city: true };
      });
    } else {
      isValid = false;
      setValidateValues((prev) => {
        return { ...prev, city: false };
      });
    }
    if (addModalCart.postalCode != "") {
      setValidateValues((prev) => {
        return { ...prev, postalCode: true };
      });
    } else {
      isValid = false;
      setValidateValues((prev) => {
        return { ...prev, postalCode: false };
      });
    }
    if (addModalCart.address != "") {
      setValidateValues((prev) => {
        return { ...prev, address: true };
      });
    } else {
      isValid = false;
      setValidateValues((prev) => {
        return { ...prev, address: false };
      });
    }
    return isValid;
  };
  const [addModalCart, setAddModalCart] = useState({
    fName: "",
    lName: "",

    city: "",
    postalCode: "",
    address: "",
    phone: "",
  });

  const addOrder = () => {
    let fd = new FormData();
    fd.append("type", 1);
    fd.append("sendtm", new Date().getTime());
    fd.append("rescipient_fname", addModalCart.fName);
    fd.append("rescipient_lname", addModalCart.lName);
    fd.append("rescipient_mobile", addModalCart.phone);
    fd.append("rescipient_address", addModalCart.address);
    fd.append("city_id", addModalCart.city.value);
    fd.append("rescipient_postalcode", addModalCart.postalCode);
    // fd.append("order_type", auth.userInfo.userType == 0 ? "1" : "0");
    fd.append("order_type", "0");
    fd.append("uid", auth.userInfo.id);
    // fd.append("user_mobile", auth.userInfo.mobile);
    // fd.append("fullname", auth.userInfo.fullName);
    // fd.append("user_fname");
    // fd.append("user_lname");
    // fd.append("order_items", JSON.stringify(items));
    for (let i = 0; i < items.length; i++) {
      fd.append(`order_items[${i}][productid]`, items[i].product_id);
      fd.append(`order_items[${i}][number]`, items[i].itemcount);
      fd.append(`order_items[${i}][discount]`, items[i].main_discount);
    }

    sendRequest2("addorder_new", fd).then((data) => {
      if (data.data.status == 1) {
        toast.success("درخواست شما با موفقیت ارسال شد");

        updateCartItem();
        setAddModalCart({
          fName: "",
          lName: "",
          city: "",
          postalCode: "",
          address: "",
          phone: "",
        });

        document.querySelector("#CartModal .btn-close").click();
        cartItemsCount("null", "0", "0");
        window.location.href = data.data.url;
        // navigation("/customer-panel/orders");
      } else {
        toast.error("درخواست شما با مشکل مواجه شد!!!");
      }
    });
  };
  const changeHandler = (value, type) => {
    setAddModalCart((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const [items, setItems] = useState([
    // {
    //   img: laptop,
    //   name: "لپ تاپ",
    //   text: "لپ تاپ 13/7 اینچی اپل",
    //   colorName: "مشکی",
    //   color: "black",
    //   price: 70000000,
    //   number: 3,
    //   amountDiscount: 5000000,
    // },
  ]);

  const getTotalPrice = () => {
    let totalPrice = 0;
    for (let index = 0; index < items?.length; index++) {
      totalPrice += parseInt(items[index].main_price * items[index].itemcount);
    }
    return totalPrice;
  };
  const getTotalDiscount = () => {
    let totalDiscount = 0;
    for (let index = 0; index < items?.length; index++) {
      totalDiscount += parseInt(
        items[index].main_discount * items[index].itemcount
      );
    }
    return totalDiscount;
  };
  const getTotalNumber = () => {
    let number = 0;
    for (let index = 0; index < items?.length; index++) {
      number += parseInt(items[index].itemcount);
    }
    return number;
  };
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityTag, setCityTags] = useState([]);

  const translateToReactSelectProvinceCity = (data) => {
    let after = [];
    for (let index = 0; index < data.length; index++) {
      let obj = {};
      obj.label = data[index].title;
      obj.value = data[index].id;

      if (data[index].provinceid) {
        obj.provinceid = data[index].provinceid;
      }
      after.push(obj);
    }
    return after;
  };

  const editCartItems = async (type, productId) => {
    let { data } = await sendRequest("cartitem", {
      situation: type,
      productId,
    });
    return +data.status;
  };

  useEffect(() => {
    // sendRequest2("get_cart_items").then(({ data }) => {
    //   setItems(data.cart_items);
    // });
    setItems(shoppingData);
    sendRequest("Find_provinces").then(({ data }) => {
      setProvinceOptions(translateToReactSelectProvinceCity(data));
    });
    sendRequest2("Find_cities").then(({ data }) => {
      setCityTags(translateToReactSelectProvinceCity(data));
    });
  }, [shoppingData]);

  const modalVar = (
    <div className="modal fade" id="CartModal">
      <div
        className="modal-dialog modal-fullscreen "
        style={{ height: "fit-content" }}
      >
        {/* {Object.keys(selectedItem).length && ( */}
        <div
          className="modal-content"
          style={{
            backgroundColor: "#f9f7f8",
          }}
        >
          <div className=" row ">
            <div className="col-12  col-lg-8">
              <div style={{ marginRight: "20px", marginTop: "15px" }}>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="d-flex-column mt-4">
                <div style={{ color: "#2e2c2d", textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }}> لیست خرید</p>
                </div>
                <div
                  style={{
                    color: "#7c87ac",
                    borderTop: "2px solid #7c87ac",
                    width: "90px",
                    margin: "auto",
                    marginBottom: "10px",
                  }}
                ></div>
              </div>

              <ShoppingListItem
                items={items}
                setItems={setItems}
                editCartItems={editCartItems}
              ></ShoppingListItem>
            </div>
            <div
              className="d-flex justify-content-center col-12  col-lg-4"
              style={{ backgroundColor: "#1d3a8c" }}
            >
              <div className="d-flex-column mt-5" style={{ width: "80%" }}>
                <div
                  style={{
                    color: "white",
                    width: "90px",
                    margin: "auto",
                    marginBottom: "10px",
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: "#f9f7f8",
                    borderRadius: "13px",
                    width: "90%",
                    textAlign: "center",
                    color: "white",
                    padding: "15px 10px",
                    fontSize: "14px",
                    marginTop: "30px",
                    margin: "auto",
                  }}
                >
                  <div style={{ color: "black", textAlign: "center" }}>
                    <p style={{ fontSize: "18px" }}>صورت حساب</p>
                  </div>
                  <div
                    style={{
                      color: "white",
                      borderTop: "1px solid black",
                      width: "90px",
                      margin: "auto",
                      marginBottom: "10px",
                    }}
                  ></div>
                  {/* <span style={{ width: "30%", alignSelf: "center" }}>
                    <span
                      style={{
                        marginRight: "10px",
                        color: "#5d616f",
                      }}
                    >
                      {getTotalNumber() }
                    </span>
                    <span style={{ marginRight: "4px", color: "#5d616f" }}>
                      کالا
                    </span>
                  </span> */}

                  {/* <span
                    style={{
                      marginRight: "5px",
                      marginLeft: "5px",
                      color: "#5d616f",
                    }}
                  >
                    :
                  </span>
                  <span style={{ marginRight: "8px", color: "#5d616f" }}>
                    {getTotalPrice().toLocaleString()}
                  </span>
                  <span style={{ marginRight: "10px", color: "#5d616f" }}>
                    تومان
                  </span> */}
                  <div style={{ marginTop: "40px" }}>
                    <span>
                      <p style={{ color: "#5d616f" }}>
                        {getTotalNumber()} کالا :
                        {getTotalPrice().toLocaleString()} تومان
                      </p>
                    </span>
                    <span>
                      <p style={{ color: "#e55c75" }}>
                        مجموع تخفیف : {getTotalDiscount().toLocaleString()}{" "}
                        تومان
                      </p>
                    </span>
                  </div>

                  {/* <div className="d-flex mt-2">
                    <span style={{ color: "#e55c75", whiteSpace: "nowrap" }}>
                      {" "}
                      مجموع تخفیف{" "}
                    </span>
                    <span
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        color: "#5d616f",
                      }}
                    >
                      :
                    </span>
                    <span style={{ color: "#e55c75" }}>
                      {getTotalDiscount().toLocaleString()}
                    </span>
                    <span style={{ marginRight: "10px", color: "#e55c75" }}>
                      تومان
                    </span>
                  </div> */}
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    marginTop: "40px",
                    borderRadius: "3px",
                    width: "fitContent",
                    textAlign: "center",
                    color: "black",
                    padding: "15px 10px",
                    fontSize: "16px",
                  }}
                >
                  اطلاعات گیرنده
                </div>
                <div className="box d-flex mt-4 text-align-baseline">
                  <div
                    style={{
                      color: "white",
                      width: "35%",
                      height: "50%",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    <label htmlFor="fName">نام </label>
                  </div>
                  <div style={{ width: "65%" }}>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="fName"
                      name="fName"
                      value={addModalCart.fName}
                      onChange={(e) => {
                        changeHandler(e.target.value, "fName");
                      }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        visibility:
                          validateValues.fName == "" ? "visible" : "hidden",
                      }}
                    >
                      لطفا نام گیرنده را وارد کنید.
                    </p>
                  </div>
                </div>
                <div className="box d-flex mt-4 text-align-baseline">
                  <div
                    style={{
                      color: "white",
                      width: "35%",
                      height: "50%",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    <label htmlFor="lName"> نام خانوادگی</label>
                  </div>
                  <div style={{ width: "65%" }}>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="lName"
                      name="lName"
                      value={addModalCart.lName}
                      onChange={(e) => {
                        changeHandler(e.target.value, "lName");
                      }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        visibility:
                          validateValues.lName == "" ? "visible" : "hidden",
                      }}
                    >
                      لطفا نام خانوادگی گیرنده را وارد کنید.
                    </p>
                  </div>
                </div>
                {/* <div className="box d-flex mt-4 text-align-baseline">
                  <div
                    style={{
                      color: "white",
                      width: "35%",
                      whiteSpace: "nowrap",
                      textAlign: "right"
                    }}
                  >
                    <label htmlFor="province">استان</label>
                  </div>
                  <div style={{ width: "65%", color: "black" }}>
                    <Select
                      // className="form-control form-select"
                      id="province"
                      options={provinceOptions}
                      value={addModalCart.province}
                      onChange={e => {
                        changeHandler(e, "province");
                        setAddModalCart(prev => {
                          let temp = { ...prev };
                          temp.city = null;
                          return { ...temp };
                        });
                      }}
                    />

                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        visibility:
                          validateValues.province == "" ? "visible" : "hidden"
                      }}
                    >
                      لطفا استان مد نظر را انتخاب کنید.
                    </p>
                  </div>
                </div> */}
                <div className="box d-flex mt-4 text-align-baseline">
                  <div
                    style={{
                      color: "white",
                      width: "35%",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    <label htmlFor="city">شهر</label>
                  </div>
                  <div style={{ width: "65%" }}>
                    <Select
                      // className="form-control form-select"
                      id="city"
                      options={cityTag}
                      //   .filter(value => {
                      //   return addModalCart.province.value == value.provinceid;
                      // })}
                      value={addModalCart.city}
                      onChange={(e) => {
                        changeHandler(e, "city");
                      }}
                    />

                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        visibility:
                          validateValues.city == "" ? "visible" : "hidden",
                      }}
                    >
                      لطفا شهرمورد نظر را وارد کنید.
                    </p>
                  </div>
                </div>
                <div className="box d-flex mt-4 text-align-baseline">
                  <div
                    style={{
                      color: "white",
                      width: "35%",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    <label htmlFor="postalCode">کدپستی</label>
                  </div>
                  <div style={{ width: "65%" }}>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="postalCode"
                      value={addModalCart.postalCode}
                      onChange={(e) => {
                        if (!isNaN(e.target.value))
                          changeHandler(e.target.value, "postalCode");
                      }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        visibility:
                          validateValues.postalCode == ""
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      لطفا کد پستی خود را وارد کنید.{" "}
                    </p>
                  </div>
                </div>
                <div className="box d-flex mt-4 text-align-baseline">
                  <div
                    style={{
                      color: "white",
                      width: "35%",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    <label htmlFor="address">آدرس </label>
                  </div>
                  <div style={{ width: "65%" }}>
                    <textarea
                      type="text"
                      required
                      className="form-control"
                      id="address"
                      value={addModalCart.address}
                      onChange={(e) => {
                        changeHandler(e.target.value, "address");
                      }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        visibility:
                          validateValues.address == "" ? "visible" : "hidden",
                      }}
                    >
                      لطفا آدرس خود را وارد کنید.
                    </p>
                  </div>
                </div>
                <div className="box d-flex mt-4 text-align-baseline">
                  <div
                    style={{
                      color: "white",
                      width: "35%",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    <label htmlFor="phon">شماره تماس</label>
                  </div>
                  <div style={{ width: "65%" }}>
                    <input
                      type="text"
                      name="phone"
                      required
                      className="form-control"
                      id="phon"
                      value={
                        addModalCart.phone == undefined
                          ? ""
                          : addModalCart.phone
                      }
                      onChange={(e) => {
                        if (!isNaN(e.target.value))
                          changeHandler(e.target.value, "phone");
                      }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        visibility:
                          validateValues.phone == "" ? "visible" : "hidden",
                      }}
                    >
                      شماره همراه وارد شده اشتباه است.
                    </p>
                  </div>
                </div>
                <div className="box-b d-flex mt-5">
                  <div
                    style={{
                      color: "white",
                      width: "60%",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    <label> در گاه پرداخت</label>
                  </div>
                  <div
                    className="item-b"
                    style={{
                      // backgroundColor: "white",
                      borderRadius: "3px",
                      marginRight: "20px",
                      width: "20%",
                      textAlign: "center",
                      // color: "black",
                      // fontSize: "14px",
                      marginRight: "10px",
                      // boxShadow:
                      //   addModalCart.BankPortal == "saderat"
                      //     ? "rgb(120, 240, 240) 0px 5px 10px"
                      //     : "none"
                    }}
                    // value={addModalCart.BankPortal}
                    // onClick={e => {
                    //   setAddModalCart(prev => {
                    //     return { ...prev, BankPortal: "saderat" };
                    //   });
                    // }}
                  >
                    <img style={{ width: "60px" }} src={zarinpal}></img>
                  </div>
                  {/* <div
                    className="bank d-flex justify-content-around"
                    style={{
                      width: "60%",
                      whiteSpace: "nowrap",
                      textAlign: "right"
                    }}
                  >
                    <div
                      className="item-b"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "3px",
                        //width: "20%",
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                        boxShadow:
                          addModalCart.BankPortal == "melat"
                            ? "rgb(120, 240, 240) 0px 5px 10px"
                            : "none"
                      }}
                      value={addModalCart.BankPortal}
                      onClick={e => {
                        setAddModalCart(prev => {
                          return { ...prev, BankPortal: "melat" };
                        });
                      }}
                    >
                      <img src={melat}></img>
                    </div>
                    <div
                      className="item-b"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "3px",
                        marginRight: "20px",
                        width: "20%",
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                        marginRight: "10px",
                        boxShadow:
                          addModalCart.BankPortal == "saderat"
                            ? "rgb(120, 240, 240) 0px 5px 10px"
                            : "none"
                      }}
                      value={addModalCart.BankPortal}
                      onClick={e => {
                        setAddModalCart(prev => {
                          return { ...prev, BankPortal: "saderat" };
                        });
                      }}
                    >
                      <img src={saderat}></img>
                    </div>
                  </div> */}
                </div>
                <div className="box row mt-5 pb-3">
                  <div
                    className="col-8 mt-2 text-light "
                    style={{ fontSize: "14px", width: "60%" }}
                  >
                    <span>مبلغ نهایی : </span>
                    <span>
                      {(getTotalPrice() - getTotalDiscount()).toLocaleString()}
                    </span>
                    <span style={{ marginRight: "10px" }}>تومان</span>
                  </div>
                  <div className=" d-flex col-4">
                    <button
                      style={{
                        backgroundColor: "#ef384f",
                        margin: " 0 auto",
                        color: "white",
                        border: "none",
                        borderRadius: "3px",
                        padding: "10px 40px ",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        if (validation()) {
                          addOrder();
                        }
                      }}
                    >
                      پرداخت
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* )} */}
      </div>
    </div>
  );

  return createPortal(modalVar, document.querySelector("#modals"));
}
