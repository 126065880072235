import { useEffect, useState } from "react";
// import useWindowSize from "utils/useWindowSize";

import DetailProduct from "components/SingleProduct/DetailProduct";
import SliderProduct from "components/SingleProduct/SliderProduct";
import "components/SingleProduct/SingleProduct.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import product section
import Product from "common/product/Product";
import GroupViewContainer from "common/Group-View/GroupViewContainer";
import { Helmet } from "react-helmet";

import sendRequest from "hooks/useAxios";

import CreateComment from "components/SingleProduct/Comments";
import { CommentList } from "components/SingleProduct/Comments";
import { pageSectionId } from "constants/constants";
import { useContext } from "react";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import { useParams } from "react-router-dom";
// function SingleProduct() {
const html = "<div>Example HTML string</div>";

function SingleProduct() {
  const auth = useContext(WithAuthentication);

  const { productId } = useParams();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true
  };
  // product page
  const [product, setProduct] = useState({ colors: [] });
  const [slider, setSlider] = useState([]);
  const isAdmin = auth.userInfo.userType == 0;
  const [commentList, setCommentList] = useState([]);
  const [updater, setUpdater] = useState(true);
  const [updaterProduct, setUpdaterProduct] = useState(true);
  const productArray = [];
  const GroupViewData = [
    {
      Component: Product,
      numberOf: 4,
      title: "محصولات",
      type: 0,
      items: productArray,
      id: 1,
      Color: "#FF0000",
      moreText: "محصولات بیشتر",
      idConfig: pageSectionId.singleProductFirstProduct
    },
    {
      Component: Product,
      numberOf: 4,
      title: "محصولات",
      type: 0,
      items: productArray,
      id: 2,
      Color: "#FF0000",
      moreText: "محصولات بیشتر",
      idConfig: pageSectionId.singleProductSecondProduct
    }
  ];

  useEffect(() => {
    sendRequest("getSingleProduct", { productId }).then(({ data }) => {
      setProduct(data.info);
      setSlider([...data.info.files.map(value => value.url), data.info.url]);
    });
  }, [updaterProduct]);
  useEffect(() => {
    sendRequest("getcomment", { product_id: productId }).then(({ data }) => {
      setCommentList(data.comments ? data.comments : []);
    });
  }, [updater]);
  return (
    <div dir="rtl">
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${product.title}`} </title>
        </Helmet>
      </div>
      <div className="mx-4 my-5 d-flex flex-wrap single-product">
        <div className="col-lg-6 col-12 ">
          {/* image slider of product(right section in web page) */}
          <SliderProduct product={slider}></SliderProduct>
        </div>
        <div className="col-lg-6 col-12">
          {/* product Details(left section in web page) */}
          <DetailProduct
            product={product}
            isAdmin={isAdmin}
            setUpdateProduct={setUpdaterProduct}
          ></DetailProduct>
        </div>
      </div>
      {product.bodytxt != "" && (
        <>
          <h4 className="mx-3">توضیحات بیشتر: </h4>
          <div
            style={{
              textAlign: "center",
              border: "1px solid gray",
              borderRadius: "7px"
            }}
            className="mx-3 p-3"
            dangerouslySetInnerHTML={{ __html: product.bodytxt }}
          ></div>
        </>
      )}

      <div>
        <GroupViewContainer GroupViewData={GroupViewData} />
      </div>

      <div className="main-title mt-3">
        <div className="">
          <div className="title text-light">نظرات کاربران</div>
          <div className="line"></div>
        </div>
      </div>

      <div>
        <CreateComment productId={productId} setUpdater={setUpdater} />
      </div>
      <div>
        <CommentList
          commentLists={commentList}
          setUpdater={setUpdater}
        ></CommentList>
      </div>
    </div>
  );
}

export default SingleProduct;
