import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function useAuthentication() {
  const [User, setUser] = useState({
    user_token: null,
    name: null,
    mobile: null,
    user_typed: null,
    logged: false,
  });
  useEffect(() => {}, [User]);
  function getInfo() {
    return User;
  }
  function setInfo(user_token, name, mobile, user_type, logged) {
    setUser({
      user_token: user_token,
      name: name,
      mobile: mobile,
      user_type: user_type,
      logged: logged,
    });
  }
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    if (getCookie("user_token") != "" && getCookie("name") != "" && getCookie("user_type") != "" && getCookie("mobile") != "") {
      setUser(getCookie("user_token"), getCookie("name"), getCookie("mobile"), getCookie("user_type"), true);
    }
  }

  function AuthError() {
    toast.info("برای فرستادن تیکت، باید به حساب کاربری خود وارد شوید", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return { User, getInfo, getCookie, checkCookie, AuthError, setInfo };
}
