import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export default function AllPages() {
  return (
    <div className="m-5">
      <Helmet>
        <title>لیست صفحات</title>
      </Helmet>
      <Link to="/">
        <button className="btn btn-primary mx-2">صفحه اصلی</button>
      </Link>
      <Link to="/blog">
        <button className="btn btn-primary mx-2">وبلاگ</button>
      </Link>{" "}
      <Link to="/store">
        <button className="btn btn-primary mx-2">فروشگاه</button>
      </Link>{" "}
      <Link to="/specific-articles">
        <button className="btn btn-primary mx-2">لیست مطالب فیلتر شده</button>
      </Link>{" "}
      <Link to="/specific-products">
        <button className="btn btn-primary mx-2">لیست محصولات فیلتر شده</button>
      </Link>
      <Link to="/product/1">
        <button className="btn btn-primary mx-2">تک محصول</button>
      </Link>
      <Link to="/article/1">
        <button className="btn btn-primary mx-2">تک مطلب</button>
      </Link>
      <div className="mt-4">پنل مشتری:</div>
      <Link to="/customer-panel/messages">
        <button className="btn btn-primary mx-2">پیام ها </button>
      </Link>
      <Link to="/customer-panel/profile">
        <button className="btn btn-primary mx-2">پروفایل </button>
      </Link>
      <Link to="/customer-panel/orders">
        <button className="btn btn-primary mx-2">سفارشات </button>
      </Link>
      <div className="mt-4">پنل ادمین:</div>
      <Link to="/admin-panel/dashboard">
        <button className="btn btn-primary mx-2">داشبورد </button>
      </Link>
      <Link to="/admin-panel/financial">
        <button className="btn btn-primary mx-2">امور مالی </button>
      </Link>
      <Link to="/admin-panel/user_management">
        <button className="btn btn-primary mx-2">مدیریت کاربران </button>
      </Link>
    </div>
  );
}
