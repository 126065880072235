import { Link } from "react-router-dom";
import Helmet from "react-helmet";
export default function notFound() {
  return (
    <div className="not-found-page">
      <Helmet>
        <title>صفحه مد نظر پیدا نشد!</title>
      </Helmet>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <div />
            <h1>404</h1>
          </div>
          <h2>صفحه مد نظر پیدا نشد!</h2>

          <Link to="/">برگشت به صفحه اصلی</Link>
        </div>
      </div>
    </div>
  );
}
