// import Select from "react-select";
import Creatable from "react-select/creatable";

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { useEffect, useState } from "react";
import {
  AiOutlineClear,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { HiOutlineSave } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import { addCommas, removeCommas } from "@persian-tools/persian-tools";
import { translateToReactSelect } from "utils/helperFunctions";
import toast from "react-hot-toast";

import React from "react";
import chroma from "chroma-js";

import Select, { StylesConfig } from "react-select";
import colors from "react-multi-date-picker/plugins/colors";

import sendRequest from "hooks/useAxios";
import { isObjectContainsInArray } from "utils/helperFunctions";

import { FilePond, registerPlugin, create } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import "filepond/dist/filepond.min.css";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import { Form } from "react-bootstrap";

// converting a color code and name to react select/ show with colors
const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles: StylesConfig = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isSelected ? chroma.contrast(color, "white") : data.color,
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? data.color : color.alpha(0.3).css(),
      },
    };
  },
  input: (styles) => ({ ...styles, border: "none" }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const translateColorToReactSelect = (arrayValue, reverse) => {
  let after = [];
  if (reverse) {
    // react select to api
    // for (let index = 0; index < arrayValue.length; index++) {
    let obj = {};
    obj.title = arrayValue.label;
    obj.id = arrayValue.value;
    obj.hex = arrayValue.color;
    obj.inventory = arrayValue.inventory;
    return obj;
    // after.push(obj);
    // }
  } else {
    // api to react select
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.label = arrayValue[index].title;
      obj.value = arrayValue[index].id;
      obj.color = arrayValue[index].hex;
      after.push(obj);
    }
  }

  return after;
};

//react select with option that you can create new brand
// { value: "create", label: "ایجاد برند جدید", id:'0' }
const translateBrandToReactSelect = (arrayValue, reverse) => {
  let after = [];
  if (reverse) {
    // react select to api
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.name = arrayValue[index].label;
      obj.id = arrayValue[index].value;
      after.push(obj);
    }
  } else {
    // api to react select
    for (let index = 0; index < arrayValue.length; index++) {
      let obj = {};
      obj.label = arrayValue[index].name;
      obj.value = arrayValue[index].id;

      after.push(obj);
    }
  }

  return after;
};

const EditProductModal = ({ product, setUpdateProduct, setUpdater, tags }) => {
  console.log("editProductModalUnique", product);
  const [editProduct, setEditProduct] = useState({
    ...product,
    // title: product?.title,
    // description: product?.description,
    // main_price: product?.main_price,
    // main_discount: product?.main_discount,
    // main_discount_endtime: product?.main_discount_endtime,
    // file: product?.file,
    // files: product?.files,
    // partners_price: product?.partners_price,
    // partners_discount: product?.partners_discount,
    // partner_discount_endtime: product?.partner_discount_endtime,
    // colors: product?.colors,
    // hashtags: product?.hashtags,
    // brandid: product?.brandid
  });
  // const [tags, setTags] = useState([]);
  const [productTag, setProductTag] = useState([]);
  // list of tags
  useEffect(() => {
    setEditProduct(product);
    if (product?.inventory_count > 0) setInventorySwitch(true);
    else setInventorySwitch(false);
  }, [product]);
  console.log(product);
  const [inventorySwitch, setInventorySwitch] = useState(false);

  useEffect(() => {
    if (editProduct.hashtags) {
      setProductTag(
        editProduct.hashtags?.map((value) => {
          if (
            typeof value.value !== "undefined" &&
            typeof value.label !== "undefined"
          ) {
            return value;
          }
          return {
            value: value.hashtags_id,
            label: value.txt,
          };
        })
      );
    }
  }, [editProduct]);

  // useEffect(() => {
  //   sendRequest("Find_hashtags").then(({ data }) => {
  //     setTags(data);
  //   });
  // }, []);
  // const tagsOptions = translateToReactSelect(tags, false);

  const insertHashtags = async () => {
    let theArr = [];

    await Promise.all(
      productTag?.map(async (arrayItem) => {
        if (arrayItem.__isNew__ == true) {
          await sendRequest("Insert_hashtags", { txt: arrayItem.label }).then(
            ({ data }) => {
              theArr.push(data.result.id);
            }
          );
        } else {
          theArr.push(+arrayItem.value);
        }
      })
    );
    return theArr;
  };
  // const insertHashtags = async tagsArray => {
  //   let theArr = [];
  //   await Promise.all(
  //     tagsArray.map(arrayItem => {
  //       if (arrayItem.__isNew__ == true) {
  //         sendRequest("Insert_hashtags", { txt: arrayItem.label }).then(
  //           ({ data }) => {
  //             theArr.push(data.result.id);
  //           }
  //         );
  //       } else {
  //         theArr.push(+arrayItem.value);
  //       }
  //     })
  //   );
  //   editProduct.hashtags = theArr;
  // };
  const [tagsOptions, setTagsOptions] = useState([]);

  useEffect(() => {
    setTagsOptions(translateToReactSelect(tags, false));
  }, [tags]);

  // edit request
  const editData = (theArr) => {
    // setEditProduct((prev) => {
    //   return { ...prev, colors: JSON.stringify(editProduct.colors) };
    // });

    let fd = new FormData();
    fd.append(
      "updateValue",
      `title,${editProduct.title}@@description,${
        editProduct.description
      }@@bodytxt,${editProduct.bodytxt}@@main_price,${
        editProduct.main_price
      }@@main_discount,${editProduct.main_discount}@@main_discount_endtime,${
        editProduct.main_discount_endtime / 1000
      }@@partners_price,${editProduct.partners_price}@@partners_discount,${
        editProduct.partners_discount
      }@@partner_discount_endtime,${
        editProduct.partner_discount_endtime.unix
      }@@inventory_count,${inventorySwitch ? 1 : 0}@@enable_upload_file,${
        editProduct.enable_upload_file
      }@@brandid,${editProduct.brandid.value}`
    );
    // fd.append(
    //   "Hashtags",
    //   editProduct.hashtags ? editProduct.hashtags.join(",") : ""
    // );
    fd.append(
      "Hashtags",
      theArr.reduce((total, value) => {
        if (!total.includes(value)) {
          total.push(value);
        }
        return total;
      }, [])
    );
    fd.append("colorInventory", JSON.stringify(editProduct.colors));
    fd.append("productId", editProduct.id);
    fd.append("file", editProduct.file);
    for (let i = 0; i < editProduct.files?.length; i++) {
      fd.append("files[]", editProduct.files[i]);
    }
    sendRequest("updateproduct", fd).then((data) => {
      if (data.status == 200) {
        toast.success("محصول با موفقیت ویرایش شد.");
        document.querySelector("#editProductModalUnique .btn-close").click();
        // setEditProduct(prev => {
        //   return { ...prev, brandid: "" };
        // });
        setUpdater((prev) => !prev);
        setUpdateProduct((prev) => !prev);
      } else {
        toast.error("مشکلی در ویرایش محصول پیش آمده است.");
      }
    });
  };

  useEffect(() => {
    setEditProduct({
      ...product,
      main_discount_endtime: product.main_discount_endtime * 1000,
      partner_discount_endtime: product.partner_discount_endtime * 1000,
    });
  }, [product]);
  const [update, setUpdate] = useState(false);
  const uploadFileFunc = (x) => {
    return x == "0" ? false : true;
  };
  // timestamo
  const timestampToDate = (time) => {
    if (time != "") {
      const a = time * 1000;
      const date = new DateObject({ date: a, calendar: persian });
      return date.format();
    }
  };

  // list of colors
  const [colors, setColors] = useState([]);
  const [colorOption, setColorOption] = useState([]);
  useEffect(() => {
    sendRequest("Find_color").then(({ data }) => {
      setColors(data);
    });
  }, []);
  useEffect(() => {
    if (editProduct.colors != null && editProduct.colors.length != 0) {
      // let x = translateColorToReactSelect(colors, false);
      // setColorOption(translateColorToReactSelect(y, false));
      const defaultColors = [];
      for (let i = 0; i < editProduct.colors.length; i++) {
        const { inventory, ...rest } = editProduct.colors[i];
        defaultColors.push(rest);
      }
      let x = colors.filter((n) => !defaultColors.some((v) => v.id == n.id));
      setColorOption(translateColorToReactSelect(x, false));
    } else {
      setColorOption(translateColorToReactSelect(colors, false));
    }
  }, [colors, editProduct.colors]);

  const insertBrand = () => {
    sendRequest("addbrand", newBrand).then(({ data }) => {
      if (data.status == 1) {
        toast.success("برند با موفقیت اضافه شد.");
        setUpdate((prev) => !prev);
      } else {
        toast.error("مشکلی در افزودن برند پیش آمده است.");
      }
    });
  };

  // list of brands
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    sendRequest("Find_brand").then(({ data }) => {
      setBrands((prev) => {
        return [{ name: "ثبت برند جدید", id: "create" }, ...data];
      });
    });
  }, [update]);
  const brandsOptions = translateBrandToReactSelect(brands, false);
  const [newBrand, setNewBrand] = useState({ name: "", file: "" });

  const [displayStatus, setDisplayStatus] = useState("d-none");

  // validation
  const [validateStatus, setValidateStatus] = useState({
    title: false,
    cover: false,
    price: false,
    description: false,
    // partnerPrice: false,
    mainDiscount: false,
    partnerDiscount: false,
    inventory: false,
  });
  const testInventory = () => {
    let add = 0;
    for (let i = 0; i < editProduct.colors?.length; i++) {
      add += +editProduct.colors[i].inventory;
    }

    if (editProduct.inventory_count >= add) {
      setValidateStatus((prev) => {
        return { ...prev, inventory: false };
      });
      return true;
    } else {
      setValidateStatus((prev) => {
        return { ...prev, inventory: true };
      });
      return false;
    }
  };
  console.log("object ***", editProduct);
  const validation = () => {
    let valid = true;
    if (editProduct.description != "") {
      setValidateStatus((prev) => {
        return { ...prev, description: false };
      });
    } else if (editProduct.description == "" && editProduct?.id != 1) {
      setValidateStatus((prev) => {
        return { ...prev, description: true };
      });
      valid = false;
    }
    if (editProduct.file != "") {
      setValidateStatus((prev) => {
        return { ...prev, cover: false };
      });
    } else if (editProduct.file == "") {
      setValidateStatus((prev) => {
        return { ...prev, cover: true };
      });
      valid = false;
    }
    // if (editProduct.main_price != "") {
    //   setValidateStatus(prev => {
    //     return { ...prev, price: false };
    //   });
    // } else if (editProduct.main_price == "") {
    //   setValidateStatus(prev => {
    //     return { ...prev, price: true };
    //   });
    //   valid = false;
    // }

    // if (editProduct.inventory_count == "" || editProduct.inventory_count == 0) {
    //   setValidateStatus(prev => {
    //     return { ...prev, inventory: true };
    //   });
    //   valid = false;
    // } else {
    //   setValidateStatus(prev => {
    //     return { ...prev, inventory: false };
    //   });
    // }

    // if (editProduct.partners_price == "") {
    //   setValidateStatus(prev => {
    //     return { ...prev, partnerPrice: true };
    //   });
    //   valid = false;
    // } else {
    //   setValidateStatus(prev => {
    //     return { ...prev, partnerPrice: false };
    //   });
    // }
    // if (!testInventory()) {
    //   valid = false;
    // }

    return valid;
  };

  const [validateInventory, setValidateInventory] = useState("d-none");

  const [inventory, setInventory] = useState({ color: "", inventory: "" });
  let inventoryDisable = inventory.color == "" ? true : false;
  // console.log("edit", editProduct);
  return (
    <>
      <div className="modal" id="editProductModalUnique" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-fullscreen-lg-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ویرایش محصول </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div>
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        class={`nav-link active`}
                        id={`nav-info-tab-${product.id}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-info-${product.id}`}
                        type="button"
                        role="tab"
                        aria-controls={`nav-info-${product.id}`}
                        aria-selected="true"
                      >
                        اطلاعات اولیه
                      </button>
                      {/* <button
                        class="nav-link"
                        id={`nav-sell-tab-${product.id}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-sell-${product.id}`}
                        type="button"
                        role="tab"
                        aria-controls={`nav-sell-${product.id}`}
                        aria-selected="false"
                      >
                        شرایط فروش
                      </button>
                      <button
                        class="nav-link"
                        id={`nav-more-tab-${product.id}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-more-${product.id}`}
                        type="button"
                        role="tab"
                        aria-controls={`nav-more-${product.id}`}
                        aria-selected="false"
                      >
                        اطلاعات تکمیلی
                      </button> */}
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    {/* محتوا اطلاعات اولیه */}
                    <div
                      class="tab-pane fade show active"
                      id={`nav-info-${product.id}`}
                      role="tabpanel"
                      aria-labelledby={`nav-info-tab-${product.id}`}
                    >
                      <div className="d-flex first-container">
                        {/* عنوان */}
                        {/* <div className="my-2 align-items-baseline title">
                          <label htmlFor="title" className="">
                            عنوان:
                          </label>
                          <input
                            id="title"
                            className="round form-control"
                            type="text"
                            value={editProduct.title}
                            required
                            onChange={(e) => {
                              setEditProduct((prev) => {
                                return { ...prev, title: e.target.value };
                              });
                            }}
                            onKeyUp={() => {
                              if (editProduct.title == "") {
                                setValidateStatus((prev) => {
                                  return { ...prev, title: true };
                                });
                              } else {
                                setValidateStatus((prev) => {
                                  return { ...prev, title: false };
                                });
                              }
                            }}
                          ></input>
                          <div
                            className={`ms-1 ${
                              validateStatus.title == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            فیلد عنوان نباید خالی باشد.
                          </div>
                        </div> */}
                        {/* برند */}
                        {/* <div className="my-2 brand mx-1">
                          <label htmlFor="brand">برند:</label>
                          <Select
                            id="brand"
                            value={
                              editProduct.brandid != "0" &&
                              brandsOptions.find(
                                (brand) => brand.value == editProduct.brandid
                              )
                            }
                            // value={brandsOptions.filter(
                            //   brand => brand.value == product.brandid
                            // )}
                            options={brandsOptions}
                            onChange={(e) => {
                              setEditProduct((prev) => {
                                return { ...prev, brandid: e };
                              });

                              if (e.value == "create") {
                                setDisplayStatus("d-block");
                              } else {
                                setDisplayStatus("d-none");
                              }
                            }}
                          />
                        </div> */}
                      </div>
                      {/* ایجاد برند جدید */}
                      {/* <div
                        className={`d-flex ${displayStatus} rounded`}
                        style={{ border: "1px dashed lightgray" }}
                      >
                        <div className="w-50 p-1">
                          <label>نام برند:</label>
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) => {
                              setNewBrand((prev) => {
                                return { ...prev, name: e.target.value };
                              });
                            }}
                          ></input>
                        </div>
                        <div className="w-50 p-1">
                          <div className="d-flex justify-content-between">
                            <label>ایکن برند:</label>
                            <HiOutlineSave
                              className="me-2 text-success  cursor-pointer"
                              data-tip="ثبت برند جدید"
                              onClick={insertBrand}
                            />
                            <ReactTooltip />
                          </div>

                          <input
                            className="form-control"
                            type="file"
                            onChange={(e) => {
                              setNewBrand((prev) => {
                                return { ...prev, file: e.target.files[0] };
                              });
                            }}
                          ></input>
                        </div>
                      </div> */}

                      {/* توضیحات */}
                      <div className="my-2 align-items-baseline">
                        <label htmlFor="txt" className="">
                          توضیحات:{" "}
                        </label>
                        <textarea
                          id="txt"
                          className=" form-control"
                          defaultValue={product.description}
                          value={editProduct.description}
                          onChange={(e) => {
                            setEditProduct((prev) => {
                              return { ...prev, description: e.target.value };
                            });
                          }}
                        ></textarea>
                        <div
                          className={`ms-1 ${
                            validateStatus.description == true
                              ? "d-block"
                              : "d-none"
                          } validation-txt`}
                        >
                          فیلد توضیحات نباید خالی باشد.
                        </div>
                      </div>

                      {/* تگهای محصول */}
                      <div className="my-2">
                        <label htmlFor="tags">برچسبهای محصول:</label>
                        <Creatable
                          id="tags"
                          options={tagsOptions}
                          isMulti={true}
                          className="mx-1"
                          value={productTag}
                          onChange={(e) => {
                            setProductTag(e);
                          }}
                        />
                      </div>
                      {product?.id != 1 && (
                        <div className="d-flex second-container">
                          <div className="my-2 mx-1 align-items-baseline other-media">
                            <label htmlFor="coverPic" className="">
                              کاور محصول:
                            </label>

                            <input
                              id="coverPic"
                              className=" form-control "
                              type="file"
                              onChange={(e) => {
                                setEditProduct((prev) => {
                                  return { ...prev, file: e.target.files[0] };
                                });
                              }}
                            ></input>
                            <div
                              className={`ms-1 ${
                                validateStatus.cover == true
                                  ? "d-block"
                                  : "d-none"
                              } validation-txt`}
                            >
                              فیلد کاور نباید خالی باشد.
                            </div>
                          </div>
                          {/* فایلهای جانبی */}
                          <div className="my-2 mx-1 align-items-baseline cover ">
                            <label htmlFor="otherMedia">فایلهای جانبی:</label>

                            <input
                              className="form-control"
                              id="otherMedia"
                              type="file"
                              multiple
                              onChange={(e) => {
                                setEditProduct((prev) => {
                                  return { ...prev, files: e.target.files };
                                });
                              }}
                            ></input>
                          </div>
                        </div>
                      )}
                      {/* اپلود فایل */}
                      {/* <div className="form-check my-2">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          id="flexCheckDefault"
                          // checked={true}
                          // defaultChecked={uploadFile}
                          checked={uploadFileFunc(
                            editProduct.enable_upload_file
                          )}
                          onChange={() => {
                            setEditProduct(prev => {
                              return {
                                ...prev,
                                enable_upload_file:
                                  editProduct.enable_upload_file == "1"
                                    ? "0"
                                    : "1"
                              };
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          امکان اپلود فایل توسط مشتری
                        </label>
                      </div> */}
                    </div>
                    {/* محتوا شرایط فروش */}
                    <div
                      class="tab-pane fade"
                      id={`nav-sell-${product.id}`}
                      role="tabpanel"
                      aria-labelledby={`nav-sell-tab-${product.id}`}
                    >
                      {/* قیمت اصلی */}
                      <div className="d-flex justify-content-between align-items-center price-date">
                        <div className="m-1 main-price-item">
                          <label htmlFor="mainPrice" className="fs-14">
                            قیمت (تومان)
                          </label>

                          <input
                            id="mainPrice"
                            className=" form-control "
                            // defaultValue={product.main_price}
                            value={addCommas(editProduct.main_price)}
                            onChange={(e) => {
                              setEditProduct((prev) => {
                                return {
                                  ...prev,
                                  main_price: removeCommas(e.target.value),
                                };
                              });
                            }}
                            onKeyUp={() => {
                              if (editProduct.main_price == "") {
                                setValidateStatus((prev) => {
                                  return { ...prev, price: true };
                                });
                              } else {
                                setValidateStatus((prev) => {
                                  return { ...prev, price: false };
                                });
                              }
                            }}
                          ></input>
                          <div
                            className={`ms-1 ${
                              validateStatus.price == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            فیلد قیمت نباید خالی باشد.
                          </div>
                        </div>
                        <div className="m-1 main-price-item">
                          <div>
                            <label htmlFor="mainDiscount" className="fs-14">
                              تخفیف(تومان)
                            </label>
                            <input
                              id="mainDiscount"
                              className=" form-control "
                              // type="number"
                              // defaultValue={product.main_discount}
                              value={addCommas(editProduct.main_discount)}
                              onChange={(e) => {
                                if (
                                  removeCommas(e.target.value) >=
                                  editProduct.main_price
                                ) {
                                  setValidateStatus((prev) => {
                                    return { ...prev, mainDiscount: true };
                                  });
                                } else {
                                  setValidateStatus((prev) => {
                                    return { ...prev, mainDiscount: false };
                                  });
                                  setEditProduct((prev) => {
                                    return {
                                      ...prev,
                                      main_discount: removeCommas(
                                        e.target.value
                                      ),
                                    };
                                  });
                                }
                              }}
                            ></input>
                          </div>
                          <div
                            className={`ms-1 ${
                              validateStatus.mainDiscount == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            مبلغ تخفیف از مبلغ اصلی نباید بیشتر باشد.
                          </div>
                        </div>

                        <div className="m-1 main-price-item">
                          <div className="d-flex justify-content-between">
                            <label htmlFor="discountDate" className="fs-14">
                              تا تاریخ
                            </label>
                            <AiOutlineClear
                              data-tip="تخفیف بدون محدودیت زمانی"
                              className="align-self-center cursor-pointer text-danger"
                              onClick={() => {
                                setEditProduct((prev) => {
                                  return {
                                    ...prev,
                                    main_discount_endtime: null,
                                  };
                                });
                              }}
                            />
                            <ReactTooltip />
                          </div>

                          <div className="w-100">
                            <DatePicker
                              className="w-100"
                              name="mainDate"
                              id="discountDate"
                              plugins={[<TimePicker position="bottom" />]}
                              calendar={persian}
                              locale={persian_fa}
                              format=" HH:mm:ss YYYY/MM/DD"
                              value={
                                editProduct.main_discount_endtime == null
                                  ? ""
                                  : editProduct.main_discount_endtime
                              }
                              onChange={(e) => {
                                setEditProduct((prev) => {
                                  return {
                                    ...prev,
                                    main_discount_endtime: e,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* قیمت همکار */}
                      <div className="d-flex justify-content-between align-items-center price-date">
                        <div className=" mx-1 my-2 main-price-item">
                          <label htmlFor="partnerPrice" className="fs-14">
                            قیمت همکار(تومان)
                          </label>
                          <input
                            id="partnerPrice"
                            className=" form-control"
                            // type="number"
                            value={addCommas(editProduct.partners_price)}
                            onChange={(e) => {
                              setEditProduct((prev) => {
                                return {
                                  ...prev,
                                  partners_price: removeCommas(e.target.value),
                                };
                              });
                            }}
                          ></input>
                          {/* <div
                            className={`ms-1 ${
                              validateStatus.partnerPrice == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            فیلد قیمت نباید خالی باشد.
                          </div> */}
                        </div>
                        <div className=" mx-1 my-2 main-price-item">
                          <div>
                            <label htmlFor="partnerDiscount" className="fs-14">
                              تخفیف همکار(تومان)
                            </label>
                            <input
                              id="partnerDiscount"
                              className=" form-control"
                              // type="number"
                              value={addCommas(editProduct.partners_discount)}
                              onChange={(e) => {
                                if (
                                  removeCommas(e.target.value) >=
                                  editProduct.partners_price
                                ) {
                                  setValidateStatus((prev) => {
                                    return { ...prev, partnerDiscount: true };
                                  });
                                } else {
                                  setValidateStatus((prev) => {
                                    return { ...prev, partnerDiscount: false };
                                  });
                                  setEditProduct((prev) => {
                                    return {
                                      ...prev,
                                      partners_discount: removeCommas(
                                        e.target.value
                                      ),
                                    };
                                  });
                                }
                              }}
                            ></input>
                          </div>
                          <div
                            className={`ms-1 ${
                              validateStatus.partnerDiscount == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            قیمت تخفیف نباید بیشتر از مبلغ کل باشد..
                          </div>
                        </div>
                        <div className=" mx-1 my-2 main-price-item">
                          <div className="d-flex justify-content-between">
                            <label
                              htmlFor="partnerDiscountDate"
                              className="fs-14"
                            >
                              تا تاریخ
                            </label>
                            <AiOutlineClear
                              data-tip="تخفیف بدون محدودیت زمانی"
                              className="align-self-center cursor-pointer text-danger"
                              onClick={() => {
                                setEditProduct((prev) => {
                                  return {
                                    ...prev,
                                    partner_discount_endtime: null,
                                  };
                                });
                              }}
                            />
                            <ReactTooltip />
                          </div>
                          <div className="w-100">
                            <DatePicker
                              className="w-100"
                              id="discountDatePartner"
                              plugins={[<TimePicker position="bottom" />]}
                              calendar={persian}
                              locale={persian_fa}
                              format=" HH:mm:ss YYYY/MM/DD"
                              value={
                                editProduct.partner_discount_endtime == null
                                  ? ""
                                  : editProduct.partner_discount_endtime
                              }
                              onChange={(value) => {
                                setEditProduct((prev) => {
                                  return {
                                    ...prev,
                                    partner_discount_endtime: value,
                                  };
                                });
                                console.log(
                                  "time :",
                                  editProduct.partner_discount_endtime
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap">
                        <div className=" p-1 color-container">
                          <label htmlFor="total-inventory">موجودی کلی:</label>
                          <input
                            id="total-inventory"
                            className=" form-control "
                            type="number"
                            value={editProduct.inventory_count}
                            onChange={(e) => {
                              setEditProduct((prev) => {
                                return {
                                  ...prev,
                                  inventory_count: e.target.value,
                                };
                              });
                            }}
                            onKeyUp={() => {
                              if (editProduct.inventory_count == "") {
                                setValidateStatus((prev) => {
                                  return { ...prev, inventory: true };
                                });
                              } else {
                                setValidateStatus((prev) => {
                                  return { ...prev, inventory: false };
                                });
                              }
                            }}
                          ></input>
                          <div
                            className={`ms-1 ${
                              validateStatus.inventory == true
                                ? "d-block"
                                : "d-none"
                            } validation-txt`}
                          >
                            فیلد موجودی نباید خالی باشد
                          </div>
                        </div>
                      </div>
                      {/*انتخاب رنگ و موجودی */}
                      {/* <div className="d-flex flex-wrap">
                        <div className=" p-1 color-container">
                          <label htmlFor="color">انتخاب رنگ:</label>
                          <Select
                            styles={colourStyles}
                            id="color"
                            value={inventory}
                            options={colorOption}
                            defaultValue={inventory}
                            onChange={e => {
                              setInventory(e);
                              // setInventory((prev) => {
                              //   return { ...prev, color: e };
                              // });
                            }}
                          ></Select>
                        </div>
                        <div className=" align-items-baseline p-1 inventory-container">
                          <label htmlFor="inventory">موجودی:</label>
                          <input
                            id="inventory"
                            className=" form-control "
                            type="number"
                            value={inventory.inventory}
                            // defaultValue={product.inventory_count}
                            onChange={e => {
                              setInventory(prev => {
                                return { ...prev, inventory: e.target.value };
                              });
                            }}
                            disabled={inventoryDisable}
                          ></input>
                        </div>
                        <div className=" align-self-end mb-2">
                          <AiOutlinePlusCircle
                            size={"20"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                inventory.color != "" &&
                                inventory.inventory != ""
                              ) {
                                setEditProduct(prev => {
                                  return {
                                    ...prev,
                                    colors: [
                                      ...prev.colors,
                                      translateColorToReactSelect(
                                        inventory,
                                        true
                                      )
                                    ]
                                  };
                                });
                                setColorOption(prev => {
                                  return prev.filter((value, index) => {
                                    return value.label == inventory.label
                                      ? false
                                      : true;
                                  });
                                });

                                setValidateInventory("d-none");
                                setInventory({ color: "", inventory: "" });
                              } else {
                                setValidateInventory("d-block");
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className={`${validateInventory} validation-txt`}>
                        رنگ و موجودی باید مقدار داشته باشند.
                      </div>
                      <ul className="d-flex flex-wrap">
                        {editProduct.colors?.map((value, index) => {
                          return (
                            <li
                              className="col-sm-6 col-12"
                              style={{ fontSize: "14px" }}
                            >
                              {`${value.inventory} عدد از رنگ ${value.title}`}
                              <AiOutlineMinusCircle
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setEditProduct(prev => {
                                    return {
                                      ...prev,
                                      colors: [...editProduct.colors].filter(
                                        (color, index2) => {
                                          return index2 != index;
                                        }
                                      )
                                    };
                                  });
                                  setColorOption(prev => {
                                    return [...prev, { ...value.color }];
                                  });
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul> */}
                    </div>
                    {/* محتوا اطلاعات تکمیلی */}
                    <div
                      class="tab-pane fade"
                      id={`nav-more-${product.id}`}
                      role="tabpanel"
                      aria-labelledby={`nav-more-tab-${product.id}`}
                    >
                      <div className="my-3" dir="">
                        <SunEditor
                          setOptions={{
                            height: 250,
                            buttonList: [
                              [],
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                                "bold",
                                "underline",
                                "strike",
                                "fontColor",
                                "textStyle",
                                "removeFormat",
                                "align",
                                "horizontalRule",
                                "list",
                                "lineHeight",
                                "link",
                                "image",
                                "video",
                                "audio",
                                "imageGallery",
                                "fullScreen",
                                "preview",
                              ],
                            ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                          }}
                          setContents={editProduct.bodytxt}
                          onChange={(content) => {
                            setEditProduct((prev) => {
                              return { ...prev, bodytxt: content };
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {product?.id != 1 && (
                  <div className="mt-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="موجود است."
                      checked={inventorySwitch}
                      onChange={(e) => {
                        setInventorySwitch((prev) => !prev);
                      }}
                    />
                  </div>
                )}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-success px-4"
                    onClick={
                      async () => {
                        if (validation()) {
                          let x = await insertHashtags(productTag);
                          editData(x);
                        }
                      }

                      //   async () => {
                      //   if (validation()) {
                      //     insertHashtags(productTag);
                      //     editData();
                      //   }
                      // }
                    }
                  >
                    ثبت
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProductModal;
