import { AiOutlineClose } from "react-icons/ai";
import { FiMinus } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";
import { useState, useContext } from "react";
import { addCommas } from "@persian-tools/persian-tools";
import CartItemsContext from "context/CardItemsProvider";

export default function ShoppingListItem({ items, setItems, editCartItems }) {
  // console.log("items", items);
  return items?.map((value, index) => {
    return (
      <ItemShoppingList
        img={value.url}
        name={value.title}
        text={value.description}
        color={value.hex}
        colorName={value.color_title}
        price={value.main_price}
        setItems={setItems}
        items={items}
        index={index}
        number={+value.itemcount}
        amountDiscount={value.main_discount}
        editCartItems={editCartItems}
        productId={value.product_id}
      ></ItemShoppingList>
    );
  });
}
export function ItemShoppingList({
  img,
  name,
  text,
  color,
  colorName,
  price,
  index,
  setItems,
  items,
  number,
  amountDiscount,
  editCartItems,
  productId,
}) {
  const { shoppingData, updateCartItem, cartItem, isLoading, setIsLoading } =
    useContext(CartItemsContext);
  async function Plus() {
    if (await editCartItems("Increase", items[index].id)) {
      setItems((prev) => {
        // console.log("prev", prev, index);

        return prev.map((value, index2) => {
          if (index2 == index) {
            value.itemcount++;
            return value;
          }
          return value;
        });
      });
    }
  }

  //function mines
  async function Mines() {
    if (await editCartItems("Decrease", items[index].id)) {
      if (number > 1) {
        setItems((prev) => {
          let temp = [...prev];
          temp[index].itemcount = temp[index].itemcount - 1;
          return [...temp];
        });
      }
    }
  }
  const RemoveCounter = async (items) => {
    // console.log(
    //   "await editCartItems",
    //   await editCartItems("drop", items[index].id)
    // );
    if (await editCartItems("drop", items[index].id)) {
      let newArray = items.filter((value, index2, array) => {
        if (index == index2) {
          return false;
        } else {
          return true;
        }
      });
      setItems([...newArray]);
    }
  };

  return (
    <div
      className="d-flex flex-wrap justify-content-between p-4"
      style={{ borderBottom: "2px solid #f1eff0" }}
    >
      <div
        className="col-6 col-sm-4 col-md-2"
        style={{
          textAlign: "right",
          alignSelf: "right",
        }}
      >
        <img
          style={{
            width: "100px",
            height: "100px",
          }}
          src={"https://ghodsfly.ir/data/" + img}
        ></img>
      </div>
      <div
        className="col-6 col-sm-5 col-md-3"
        style={{
          paddingTop: "20px",
        }}
      >
        <div>
          <p style={{ fontSize: "12px", color: "#a3a1a2" }}>{name}</p>
        </div>
        <div
          style={{
            width: "150px",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              color: "#5d616f",
              fontWeight: "bold",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </p>
        </div>
      </div>
      {/* <div
        className="d-flex  col-3 col-sm-3 col-md-2 "
        style={{ textAlign: "center", alignSelf: "center" }}
      >
        <div
          style={{
            backgroundColor: color != undefined ? color : "white",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            border: "1px solid #A9A9A9",
          }}
        ></div>
        <span style={{ marginRight: "15px" }}>{colorName}</span>
      </div> */}
      <div
        className="d-flex col-4 col-sm-4 col-md-2 "
        style={{ textAlign: "center", alignSelf: "center" }}
      >
        <span
          style={{ marginLeft: "10px", cursor: "pointer", marginTop: "8px" }}
        >
          <button
            // onClick={Plus}
            onClick={() => {
              cartItem("increase", productId);
            }}
            style={{ border: "none", backgroundColor: "#f9f7f8" }}
          >
            <FiPlus></FiPlus>
          </button>
        </span>

        <div
          style={{
            backgroundColor: "f9f7f8",
            width: "30px",
            height: "30px",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <p>{number}</p>
        </div>
        <span
          style={{ marginRight: "10px", cursor: "pointer", marginTop: "8px" }}
        >
          <button
            // onClick={Mines}
            onClick={() => {
              cartItem("decrease", productId);
            }}
            style={{ border: "none", backgroundColor: "#f9f7f8" }}
          >
            <FiMinus></FiMinus>
          </button>
        </span>
      </div>
      {amountDiscount != "0" ? (
        <div
          className="col-4 col-sm-4 col-md-2"
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <p style={{ color: "#e55c75", padding: "0", margin: "0" }}>
            <del>{addCommas((price * number).toLocaleString())}</del>
          </p>
          <p style={{ color: "#e55c75", padding: "0", margin: "0" }}>
            {addCommas((price - amountDiscount) * number).toLocaleString()}
          </p>
        </div>
      ) : (
        <div
          className="col-4 col-sm-4 col-md-2"
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <p style={{ color: "#e55c75", padding: "0", margin: "0" }}>
            {addCommas(price * number).toLocaleString()}
          </p>
        </div>
      )}
      <div
        className="col-1 col-sm-4 col-md-1"
        style={{ textAlign: "center", alignSelf: "center" }}
      >
        {/* <button
          style={{ border: "none", backgroundColor: "#f9f7f8" }}
          onClick={() => {
            RemoveCounter(items);
          }}
        >
          <AiOutlineClose></AiOutlineClose>
        </button> */}
      </div>
    </div>
  );
}
