import { useEffect, useState } from "react";
import ModalImg from "assets/images/IMG_20220311_170947_345.png";
import sendRequest from "hooks/useAxios";
import Swal from "sweetalert2";
import AuthContext from "Authentication/AuthenticationContext.ts";
import { useContext } from "react";
import logo from "assets/images/oil/logo2.png";
import logoName from "assets/images/Logos/New Project.png";
import CartItemsContext from "context/CardItemsProvider";
// import { USER_TYPE } from "constants/constants.js";
export default function LogInNumber({
  ChangeModal,
  loginkey,
  SetNumber,
  number,
}) {
  const [code, setcode] = useState("");
  const auth = useContext(AuthContext);
  let SetCode = (e) => {
    setcode(e.target.value);
  };
  const {
    shoppingData,
    updateCartItem,
    cartItem,
    isLoading,
    setIsLoading,
    setCountUpdater,
  } = useContext(CartItemsContext);

  useEffect(() => {
    (() => {
      "use strict";

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.querySelectorAll(".needs-validation");
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms).forEach((form) => {
        form.addEventListener(
          "submit",
          (event) => {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }
            event.preventDefault();
            form.classList.add("was-validated");
          },
          false
        );
      });
    })();
  }, []);

  let SendCode = () => {
    sendRequest("login", { mobile: number, hashcode: loginkey, code }).then(
      ({ data }) => {
        if (data.status == 1) {
          let userType;
          // if (data.userinfo[0].isadmin == 1) {
          //   switch (+data.userinfo[0].user_admin_group) {
          //     case 1:
          //       userType = USER_TYPE.generalAdmin;
          //       break;
          //     case 2:
          //       userType = USER_TYPE.tourLeader;
          //       break;
          //     case 3:
          //       userType = USER_TYPE.accountants;
          //       break;
          //     case 4:
          //       userType = USER_TYPE.saleCounter;

          //     default:
          //       break;
          //   }
          // } else {
          //   userType = USER_TYPE.customer;
          // }
          auth.login(
            data.data.usertoken,
            data.userinfo[0].fname + " " + data.userinfo[0].lname,
            +data.userinfo[0].type,
            data.userinfo[0].mobile,
            "",
            [],
            +data.userinfo[0].id
          );

          Swal.fire({
            title: "خوش آمدید",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          document.querySelector("#CloseModal").click();
          ChangeModal(0);
          updateCartItem();
        } else if (data.status == 0) {
          Swal.fire({
            title: "خطا!",
            text: "لطفا دوباره امتحان کنید",
            type: "error",
            confirmButtonText: "باشه",
          });
        } else {
          Swal.fire({
            title: "خطا!",
            text: "شما مجاز نیستید",
            type: "error",
            confirmButtonText: "باشه",
          });
        }
      }
    );
  };
  return (
    <form className="needs-validation" noValidate onSubmit={SendCode}>
      <div className="modal-body text-center">
        {/* <img
          // src={logo}
          src={logoName}
          width="200px"
          className="my-4"
          alt=""
          style={{
            backgroundColor: "rgb(220 53 69)",
            padding: "5px",
            borderRadius: "7px",
          }}
        /> */}
        GHODSFLY
        {/* <div className="d-flex "> */}
        <h5>ورود یا ثبت نام در ghodsfly </h5>
        {/* <img src={logo} style={{ width: "70px", height: "60px" }} /> */}
        {/* </div> */}
        <label htmlFor="number" className="mb-4 my-3 text-muted">
          برای ورود به ghodsfly کد دریافتی را وارد کنید
        </label>
        <input
          onChange={SetCode}
          value={code}
          type="number"
          style={{
            height: "50px",
            borderRadius: "15px",
            direction: "ltr",
            fontFamily: "digits",
          }}
          id="number"
          placeholder="کد دریافتی"
          className="form-control w-75 mx-auto"
          required
        />
        <div
          className="invalid-feedback text-start"
          style={{ marginRight: "63px" }}
        >
          لطفا کد دریافتی را وارد کنید
        </div>
        <label
          htmlFor="number"
          className="text-start  d-block my-3"
          style={{ marginRight: "63px", color: "#1D3354" }}
        >
          کد دریافتی را وارد کنید
        </label>
        <button
          type="submit"
          style={{
            height: "50px",
            borderRadius: "15px",
            backgroundColor: "#dc3545",
            color: "white",
          }}
          className="btn my-3 w-75"
        >
          ارسال
        </button>
      </div>
    </form>
  );
}
