import { useState, useEffect, useContext } from "react";
import sendRequest from "hooks/useAxios";
import { sendRequest3 } from "hooks/useAxios";
import HashtagList from "components/HashtagBase/HashtagList";
import "../components/HashtagBase/hashtags.scss";
import "../components/store/addProductModal/AddProductModal.scss";
import WithAuthentication from "Authentication/AuthenticationContext.ts";
import Loader from "utils/Loader";
import { Slide } from "components/store/AmazingProduct";
import Pagination from "rc-pagination";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";
import AddProductModal from "components/store/addProductModal/AddProductModal";
import EditProductModal from "components/SingleProduct/EditProductModal";
import ReactDOM from "react-dom";
import CartItemsContext from "context/CardItemsProvider";
import Search from "components/main/Search";
import { toast } from "react-toastify";
import oilLogo from "assets/images/Logos/New Project.png";
import AddLead from "components/AddLead/AddLead";
import coverImg from "../assets/images/new/cover.jpg";
import ReactTooltip from "react-tooltip";

export default function Hashtags() {
  const auth = useContext(WithAuthentication);
  const isAdmin = auth.userInfo.userType == 0;
  const [hashtagList, setHashtagList] = useState([]);
  // const [selectedHashtag, setSelectedHashtag] = useState(-1);
  const [selectHashtags, setSelectHashtags] = useState([]);
  const [selectedEditProduct, setSelectedEditProduct] = useState({});
  const [productList, setProductList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddLeadModal, setShowAddLeadModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [specialProduct, setSpecialProduct] = useState({});
  const [tags, setTags] = useState([]);
  const [productListUpdater, setProductListUpdater] = useState(false);
  const {
    shoppingData,
    updateCartItem,
    cartItem,
    isLoading,
    setIsLoading,
    productUpdater,
    setProductUpdater,
    cartItemCounter,
    setCountUpdater,
    cartItemsCount,
  } = useContext(CartItemsContext);

  const [show, setShow] = useState(false);

  const firstCurrentPage = () => {
    setCurrentPage(1);
  };
  const hashtagHandler = (type, id) => {
    if (type == "all") {
      setSelectHashtags([]);
    } else if (type == "other") {
      !selectHashtags.includes(id)
        ? setSelectHashtags((prev) => {
            return [...prev, id];
          })
        : setSelectHashtags(selectHashtags.filter((s) => s != id));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // let temp = { tbl_name: `left join,files,coverimg_fileid,id` };
    // if (selectedHashtag != -1) {
    //   temp = {
    //     ...temp,
    //     conditions: `/exists/(SELECT * FROM products_hashtags_links WHERE (hashtag_id = ${selectedHashtag} AND products.id=products_hashtags_links.product_id))`,
    //   };
    // }
    let id = { hashtags: "" };
    // if (selectedHashtag != -1) {
    //   id = { hashtags: `${selectedHashtag}` };
    // }
    if (selectHashtags.length > 0) {
      id = { hashtags: `${selectHashtags.join(",")}` };
    }
    async function LoadPage() {
      await sendRequest("Find_hashtags").then((data) => {
        setHashtagList(data.data);
        setTags(data.data);
      });
      // await sendRequest("Find_products", temp).then((data) => {
      //   let temp = [...data.data];

      //   for (let i = 0; i < temp.length; i++) {
      //     temp[i] = { ...temp[i], cartItem_count: "0" };
      //   }

      //   setProductList(temp.reverse());
      //   setCountUpdater((prev) => !prev);
      // });
      await sendRequest3("getproduct", id).then((data) => {
        let temp = [...data.data.data];

        for (let i = 0; i < temp.length; i++) {
          temp[i] = { ...temp[i], cartItem_count: "0" };
        }
        setProductList(temp.filter((value) => value?.product?.id != 1));
        setSpecialProduct(temp.filter((value) => value?.product?.id == 1));
        setCountUpdater((prev) => !prev);
      });

      setIsLoading(false);
    }
    LoadPage();
  }, [selectHashtags, productListUpdater]);
  console.log("object *** update", productListUpdater);
  return (
    <div>
      <img src={coverImg} style={{ width: "100%" }} />{" "}
      <div className="hashtags-base mx-auto">
        {isLoading && <Loader />}
        {/* <div className="mt-4">
      {" "}
      <Search setSelectedHashtag={setSelectedHashtag} />
    </div> */}

        {/* <HashtagList
      list={hashtagList}
      // selectedHashtag={selectedHashtag}
      // setSelectedHashtag={setSelectedHashtag}
      selectHashtags={selectHashtags}
      hashtagHandler={hashtagHandler}
      firstCurrentPage={firstCurrentPage}
    /> */}

        {isAdmin && (
          <>
            <div className="d-flex justify-content-start mt-4">
              <button
                className="btn mx-3 "
                style={{
                  backgroundColor: "darkslateblue",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "13px",
                }}
                onClick={() => {
                  setShow(true);
                }}
                // data-bs-toggle="modal"
                // data-bs-target="#addProductModal"
              >
                <HiOutlineViewGridAdd
                  className="mx-1"
                  style={{ width: 15, height: 15 }}
                />
                افزودن محصول
              </button>
            </div>
          </>
        )}
        <div className=" my-3 text-center">
          {(specialProduct[0]?.product?.description != "" || isAdmin) && (
            <div
              className=" rounded p-2 shadow"
              style={{ border: `2px dashed darkslateblue` }}
            >
              <div>{specialProduct[0]?.product?.description}</div>
              {isAdmin && (
                <div className="d-flex">
                  {" "}
                  <button
                    className="btn rounded-0 ms-auto"
                    // style={{ bottom: "0" }}
                    data-bs-toggle="modal"
                    data-bs-target="#editProductModalUnique"
                    onClick={() => {
                      setSelectedEditProduct(specialProduct[0]?.product);
                      // console.log(productDetail);
                    }}
                    data-tip="ویرایش محصول"
                  >
                    <ReactTooltip />
                    <FaRegEdit color="red" />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="d-flex flex-wrap products-list">
          {productList.length == 0 ? (
            <div className="mx-auto">
              در حال حاضر محصولی برای این دسته بندی وجود ندارد.
            </div>
          ) : (
            productList
              // .slice((currentPage - 1) * 12, (currentPage - 1) * 12 + 12)
              .map((value) => {
                return (
                  <div className=" col-xxl-2 col-lg-3 col-md-4  col-sm-6 col-12 px-1 py-2">
                    <Slide
                      productDetail={{
                        ...value.product,
                        hashtags: value.hashtags,
                        otherMedia: value?.othermedia,
                        cartItem_count:
                          cartItemCounter[`pid${value.product.id}`] == undefined
                            ? "0"
                            : cartItemCounter[`pid${value.product.id}`],
                        // value.cartItem_count
                      }}
                      type="hashtags"
                      isAdmin={isAdmin}
                      selectedEditProduct={selectedEditProduct}
                      setSelectedEditProduct={setSelectedEditProduct}
                      cartItem={cartItem}
                      updater={productUpdater}
                      setUpdater={setProductListUpdater}
                      cartItemsCount={cartItemsCount}
                      setShowAddLeadModal={setShowAddLeadModal}
                      setSelectedProduct={setSelectedProduct}
                    />
                  </div>
                );
              })
          )}
        </div>
        {/* <div className="d-flex justify-content-center my-4">
          <div>
            <Pagination
              total={productList.length}
              pageSize={12}
              current={currentPage}
              onChange={(newPage) => {
                setCurrentPage(newPage);
              }}
            ></Pagination>
          </div>
        </div> */}
        {/* edit modal */}
        {/* {ReactDOM.createPortal( */}

        <AddProductModal
          setUpdater={setProductListUpdater}
          tags={tags}
          show={show}
          setShow={setShow}
          navFirst="home"
        />
        {/* document.getElementById("addProduct") */}
        {/* )} */}
        {/* edit modal */}
        {ReactDOM.createPortal(
          <EditProductModal
            product={selectedEditProduct}
            tags={tags}
            setUpdater={setProductListUpdater}
            // setUpdateProduct={setUpdateProduct}
          />,
          document.getElementById("editProduct")
        )}
        <AddLead
          showModal={showAddLeadModal}
          setShowModal={setShowAddLeadModal}
          selectedProduct={selectedProduct}
        />
      </div>
    </div>
  );
}
