// import { IoIosArrowBack } from "react-icons/io";
import { useState, useEffect } from "react";

import read from "../../assets/images/4.svg";

// datepicker
import persian from "react-date-object/calendars/persian";
import { DateObject } from "react-multi-date-picker";

// css
import "./message.css";

// icons
import { IoMdSettings } from "react-icons/io";
import { RiMoneyDollarBoxFill, RiFileList3Fill } from "react-icons/ri";
import { BsFillBellFill } from "react-icons/bs";

function Message({ type, time, description }) {
  const calculateTime = () => {
    const currentTime = new Date().getTime();
    const x = Math.round(currentTime / 1000) - time;
    const y = x / 3600;
    const z = Math.round(y);

    if (z >= 0 && z <= 1) {
      return " کمتر از یک ساعت قبل";
    } else if (z > 1 && z < 24) {
      return `${z} ساعت قبل`;
    } else if (z < 168 && z >= 24) {
      const a = z / 24;
      const b = Math.round(a);
      return `${b} روز قبل`;
    } else if (z >= 168) {
      const a = time * 1000;
      const date = new DateObject({ date: a, calendar: persian });
      const b = new Date(a);
      return date.format();
    }
  };

  const notice = (
    <div className="d-flex align-items-baseline">
      <div
        className=" rounded-circle d-flex justify-content-center align-items-center"
        style={{ minWidth: "35px", height: "35px", backgroundColor: "#ECA72C" }}
      >
        <BsFillBellFill color="white" />
      </div>
      <div className="mx-2 message-line-height "> {description} </div>
    </div>
  );

  const repair = (
    <div className=" ">
      <div className="d-flex align-items-baseline">
        <div
          className=" rounded-circle text-center d-flex  justify-content-center align-items-center"
          style={{
            minWidth: "35px",
            height: "35px",
            backgroundColor: "#203B8C",
          }}
        >
          <IoMdSettings color="white" />
        </div>
        <div className="mx-2 message-line-height justify">{description}</div>
      </div>
      {/* <div className="d-flex my-4">
        <div className="d-flex operation-btn-container rounded mx-auto">
          {operations.map((value, index, array) => {
            const border =
              index == array.length - 1 ? "" : "border-operation-btn";
            return (
              <div>
                <button className={`btn operation-btn ${border}`}>
                  {value.title}
                </button>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );

  const order = (
    <div className=" ">
      <div className="d-flex align-items-baseline">
        <div
          className=" rounded-circle text-center  d-flex  justify-content-center align-items-center"
          style={{
            minWidth: "35px",
            height: "35px",
            backgroundColor: "#56A689",
          }}
        >
          <RiFileList3Fill color="white" />
        </div>
        <div className="mx-2 message-line-height justify">{description}</div>
      </div>
      {/* <div className="d-flex my-4">
        <div className="d-flex operation-btn-container rounded mx-auto">
          {operations.map((value, index, array) => {
            const border =
              index == array.length - 1 ? "" : "border-operation-btn";
            return (
              <div>
                <button className={`btn operation-btn ${border}`}>
                  {value.title}
                </button>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
  const finance = (
    <div className=" ">
      <div className="d-flex align-items-baseline">
        <div
          className=" rounded-circle text-center d-flex  justify-content-center align-items-center"
          style={{
            minWidth: "35px",
            height: "35px",
            backgroundColor: "#EF394E",
          }}
        >
          <RiMoneyDollarBoxFill color="white" />
        </div>
        <div className="mx-2 message-line-height justify">{description}</div>
      </div>
      {/* <div className="d-flex my-4">
        <div className="d-flex operation-btn-container rounded mx-auto">
          {operations.map((value, index, array) => {
            const border =
              index == array.length - 1 ? "" : "border-operation-btn";
            return (
              <div>
                <button className={`btn operation-btn ${border}`}>
                  {value.title}
                </button>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );

  const messageType = () => {
    switch (type) {
      case 0:
        return order;
      case 1:
        return repair;
      case 2:
        return notice;
      case 3:
        return finance;
    }
  };

  return (
    <div className="customer-message shadow m-4 d-flex flex-column p-3 align-items-baseline">
      <div className=" text-secondary ms-auto" style={{ fontSize: "14px" }}>
        {calculateTime()}
      </div>
      <div className="w-100">{messageType()}</div>
    </div>
  );
}

export default Message;
